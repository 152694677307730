import { createI18n } from 'vue-i18n';
import PL_LOCALE from '../locales/pl.json';
import EN_LOCALE from '../locales/en.json';
import DE_LOCALE from '../locales/de.json';
import FR_LOCALE from '../locales/fr.json';
import IT_LOCALE from '../locales/it.json';
import HU_LOCALE from '../locales/hu.json';
import ES_LOCALE from '../locales/es.json';
import PT_LOCALE from '../locales/pt.json';

const defaultBrowserLanguage = window.navigator.language.toUpperCase().split('-')[0];
const messages = {
  PL: PL_LOCALE,
  EN: EN_LOCALE,
  DE: DE_LOCALE,
  FR: FR_LOCALE,
  IT: IT_LOCALE,
  HU: HU_LOCALE,
  ES: ES_LOCALE,
  PT: PT_LOCALE,
};
const locale = createI18n({
  locale: defaultBrowserLanguage,
  fallbackLocale: 'PL',
  legacy: true,
  globalInjection: true,
  messages,
});
export default locale;
