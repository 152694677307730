<template>
  <DataLoader ref="loader">
    <div>
      <router-view></router-view>
      <div class="mb-10">
        <template v-if="contract.priority">
          <MountedTeleport to="#toolbox">
            <div class="flex gap-4" v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)">
              <ZButton
                v-if="contract.status == SYMBOL.CONTRACT_STATUS.NEW_ORDER"
                variant="destructive"
                :disabled="!contract.contracted_actions_count"
                @click="toggleStatus()"
              >
                {{ $t('contracts.command.cancel_contract') }}
              </ZButton>
              <ZButton
                v-if="contract.status == SYMBOL.CONTRACT_STATUS.DRAFT"
                :disabled="!contract.contracted_actions_count"
                @click="toggleStatus()"
              >
                {{ $t('contracts.command.send_contract') }}
              </ZButton>
              <div
                v-if="
                  access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true) &&
                  (contract.can_be_modified || contract.can_be_deleted)
                "
                class="max-w-36 ml-auto"
              >
                <DropdownMenu>
                  <DropdownMenuTrigger as-child>
                    <ZButton class="w-full">
                      <CogIcon class="icon sm" />
                      <span class="ml-2">
                        {{ $t('contracts.command.manage') }}
                      </span>
                      <ChevronDownIcon class="icon xs ml-2" />
                    </ZButton>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent class="w-56">
                    <DropdownMenuGroup>
                      <DropdownMenuItem :disabled="!contract.can_be_modified" @click="editContractData()">
                        <span>{{ $t('contracts.command.edit_contract_data') }}</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem :disabled="!contract.can_be_modified" @click="addContractActions()">
                        <span>{{ $t('contracts.command.add_contract_actions') }}</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem :disabled="!contract.can_be_deleted" @click="deleteContract()">
                        <span>{{ $t('contracts.command.delete_contract') }}</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </MountedTeleport>
          <div class="flex flex-col gap-5 mt-4">
            <div class="flex flex-col gap-3 rounded-xl border bg-card text-card-foreground">
              <div class="flex p-6 flex-col lg:flex-row gap-4 w-full">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 flex-1">
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.contract_number') }}</span>
                    <span class="i-value">
                          {{ contract!.contract_full_number! }}
                        </span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.status') }}</span>
                    <span class="i-value">
                          <PrettyStatus :status="contract!.status!" :translate="'contract.status.'" />
                        </span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.priority') }}</span>
                    <span class="i-value">
                          {{ $t('contracts.priority.' + priorities[contract!.priority!]) }}
                        </span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.author') }}</span>
                    <span class="i-value"> {{ contract?.user_name! }} </span>
                  </div>
                  <div class="i-block" v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
                    <span class="i-label">{{ $t('common.carbon_value') }}</span>
                    <span class="i-value"
                    ><b class="text-lg font-bold">{{
                        contract?.carbon_footprint ? readableCarbonValue(contract?.carbon_footprint) : '-'
                      }}</b></span
                    >
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.assets_count') }}</span>
                    <span class="i-value"> {{ contract?.contracted_assets_count }} </span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.actions_count') }}</span>
                    <span class="i-value"> {{ contract?.contracted_actions_count! }} </span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.contract_created_at') }}</span>
                    <span class="i-value">{{ formatDate(contract?.created_at) }}</span>
                  </div>
                  <div class="i-block">
                    <span class="i-label">{{ $t('contracts.label.estimated_deadline_at') }}</span>
                    <span class="i-value">{{
                        contract?.estimated_deadline_at ? formatDate(contract?.estimated_deadline_at) : '-'
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="contract && contract.description" class="rounded-xl border bg-card text-card-foreground">
              <div class="m-5 flex items-center justify-between">
                <h3 class="font-semibold leading-none tracking-tight">{{ $t('contracts.label.contract_text') }}</h3>
              </div>
              <div class="m-5">
                {{ contract?.description ? contract.description : '' }}
              </div>
            </div>
          </div>

          <Tabs v-model="currentTab" class="py-5">
            <TabsList
              class="lg:mt-4 no-scrollbar !flex justify-start overflow-x-auto lg:overflow-visible items-center gap-4 lg:gap-0 grid py-5 w-full"
            >
              <TabsTrigger class="w-full" value="actions">
                <div class="relative flex items-center gap-2">
                  {{ $t('contracts.label.navigation_actions') }}
                  <div
                    class="lg:absolute -top-4 -right-5 w-[18px] h-[18px] bg-primary rounded-full text-white text-[9px] flex items-center justify-center"
                  >
                    {{ contract.contracted_actions.length }}
                  </div>
                </div>
              </TabsTrigger>
              <TabsTrigger class="w-full" value="histories">
                {{ $t('contracts.label.navigation_histories') }}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="actions">
              <IconAlert
                v-if="contract.contracted_actions?.length == 0 && contract.can_be_modified"
                type="info"
                class="mt-2"
              >
                {{ $t('contracts.message.contract_no_actions') }}
                <a href="#" class="link" @click.prevent="addContractActions">{{
                  $t('contracts.command.add_first_action')
                }}</a>
              </IconAlert>

              <div v-if="contract.contracted_actions?.length" class="mt-4">
                <div class="lg:flex border rounded-lg">
                  <div class="lg:w-2/5">
                    <div class="flex p-4 justify-between items-center pb-3 border-b">
                      <div class="font-bold">{{ $t('contracts.label.actions') }}</div>
                      <ZButton
                        v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                        class="h-7"
                        variant="default"
                        :disabled="!contract.can_be_modified"
                        @click="addContractActions"
                      >
                        <span>{{ $t('contracts.command.add_contract_actions') }}</span>
                      </ZButton>
                    </div>
                    <div
                      v-for="(action, index) in contract.contracted_actions"
                      :key="index"
                      class="flex justify-between items-center bg-gray-50 py-3 px-4 m-4 rounded-lg hover:bg-accent cursor-pointer"
                      :class="{ 'bg-primary/20': selectedAction && selectedAction.id == action.id }"
                      @click="selectContractedAction(index)"
                    >
                      <div class="w-full flex flex-col gap-1">
                        <div class="font-semibold flex gap-2 items-center justify-between">
                          <div>
                          <span class="text-xs inline-block align-middle mr-1">
                            <span class="inline-block p-1 bg-white border px-2 rounded border-gray-100">#{{ action!.ref_number }}</span>
                          </span>
                            {{ action!.action!.name! }}
                          </div>
                          <PrettyStatus :status="action?.status" :translate="'contract.status.'"/>
                        </div>
                        <div class="text-xs">{{ action!.notes }}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="selectedAction"
                    class="w-full lg:w-3/5 lg:border-l lg:shadow-[-10px_0px_20px_-15px_rgba(0,0,0,0.15)]"
                  >
                    <div class="flex flex-col lg:flex-row gap-2 p-4 justify-between items-center pb-3 border-b">
                      <div class="font-bold flex flex-row gap-2 items-center">
                      <span class="text-xs inline-block align-middle mr-1">
                        <span class="inline-block p-1 bg-gray-500 text-white border px-2 rounded border-gray-100">#{{ selectedAction!.ref_number }}</span>
                      </span>
                        <PrettyStatus :status="selectedAction?.status" :translate="'contract.status.'"/>
                        {{ selectedAction!.action!.name! }}
                      </div>
                      <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="w-full lg:max-w-36">
                        <DropdownMenu>
                          <DropdownMenuTrigger as-child>
                            <ZButton variant="outline" class="h-7 w-full">
                              <span class="ml-2">
                                {{ $t('contracts.label.action_options') }}
                              </span>
                              <ChevronDownIcon class="icon xs ml-2" />
                            </ZButton>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent class="w-56">
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                :disabled="!contract.can_be_modified"
                                @click="editAction(selectedAction!.id!)"
                              >
                                <span>{{ $t('contracts.command.edit_action') }}</span>
                              </DropdownMenuItem>
                              <DropdownMenuSeparator />
                              <DropdownMenuItem
                                :disabled="!contract.can_be_modified"
                                @click="assignAssets(selectedAction!.id!)"
                              >
                                <span>{{ $t('contracts.command.assign_assets') }}</span>
                              </DropdownMenuItem>
                              <DropdownMenuSeparator />
                              <DropdownMenuItem
                                :disabled="!selectedAssets.length || !contract.can_be_modified"
                                @click="removeAssets(selectedAction!.id!)"
                              >
                                <span>{{ $t('contracts.command.remove_assets') }}</span>
                              </DropdownMenuItem>
                              <DropdownMenuSeparator />
                              <DropdownMenuItem
                                :disabled="!contract.can_be_modified"
                                @click="deleteAction(selectedAction!.id!)"
                              >
                                <span>{{ $t('contracts.command.delete_action') }}</span>
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                    <div>
                      <div class="grid grid-cols-1 divide-y">
                        <div v-if="selectedAction!.notes != '' && selectedAction!.notes != undefined" class="p-4">
                          <div class="text-xs">{{ $t('contracts.label.note') }}</div>
                          <div>{{ selectedAction!.notes! }}</div>
                        </div>
                        <div v-if="selectedAction.amount_value" class="p-4">
                          <div class="text-xs">{{ selectedAction!.action!.amount_title! }}</div>
                          <div>{{ selectedAction!.amount_value }}</div>
                        </div>
                        <div v-if="selectedAction.location_value" class="p-4">
                          <div class="text-xs">{{ selectedAction!.action!.location_title! }}</div>
                          <div>{{ selectedAction!.location_value }}</div>
                        </div>
                        <div v-if="selectedAction.checkbox_value" class="p-4">
                          <div class="text-xs">{{ selectedAction!.action!.checkbox_title! }}</div>
                          <div>{{ selectedAction.checkbox_value ? 'Tak' : 'Nie' }}</div>
                        </div>
                        <div v-if="selectedAction.deadline_value" class="p-4">
                          <div class="text-xs">{{ selectedAction!.action!.deadline_title! }}</div>
                          <div>{{ formatDate(selectedAction.deadline_value) }}</div>
                        </div>
                      </div>
                      <div v-if="selectedAction && actionAssetDataSet && actionAssetDataSet.data">
                        <div class="flex justify-between items-center p-4 border-y">
                          <div class="font-bold">{{ $t('contracts.label.contracted_assets') }}</div>
                          <ZButton
                            v-if="contract.can_be_modified && access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
                            class="h-7"
                            variant="default"
                            @click="assignAssets(selectedAction!.id!)"
                          >
                            <span>{{ $t('contracts.command.assign_assets') }}</span>
                          </ZButton>
                        </div>
                        <div v-if="actionAssetDataStatus == 'loading'">
                          <IconAlert type="loading">
                            {{ $t('common.action.loading') }}
                          </IconAlert>
                        </div>
                        <div v-else-if="selectedAction && actionAssetDataSet && actionAssetDataSet.data">
                          <div
                            v-for="(item, index) in actionAssetDataSet.data"
                            :key="index"
                            class="shadow-sm border m-5 relative rounded-lg grid grid-cols-1 divide-y"
                            :class="{ 'border-primary': selectedAssets.includes(item.id) }"
                          >
                            <div class="flex items-center justify-between">
                              <FileAttach
                                :id="item!.asset!.id!"
                                ref="attachFileDialog"
                                :contracted-asset-id="item!.id!.toString()"
                                :on-update="refreshAssetFiles"
                                type="contractAsset"
                              />
                              <div class="absolute flex justify-center -bottom-2.5 right-0 left-0">
                                <ZButton
                                  class="h-5 text-xs font-semibold"
                                  variant="outline"
                                  @click="toggleVisibleDetails(item.id)"
                                >
                                  {{
                                    visibleDetails.indexOf(item.id)
                                      ? $t('common.show_details')
                                      : $t('common.hide_details')
                                  }}
                                </ZButton>
                              </div>
                              <div class="flex items-center gap-3 p-4">
                                <div
                                  v-if="
                                    contract.can_be_modified && access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)
                                  "
                                  class="absolute -top-4 -left-2 bg-white"
                                >
                                  <Checkbox
                                    id="is_required"
                                    class="h-5 w-5 mt-2"
                                    :checked="selectedAssets.includes(item.id)"
                                    @update:checked="toggleSelectedAsset(item.id)"
                                  />
                                </div>
                                <div>
                                  <img
                                    v-if="item!.asset!.thumb"
                                    class="w-[105px] h-[105px] object-cover rounded-xl hidden md:block"
                                    :src="item!.asset!.thumb"
                                    alt="primaryImage"
                                  />
                                </div>
                                <div>
                                  <div class="flex flex-col text-sm">
                                    {{ item!.asset!.category!.name }}
                                    <b
                                      >{{
                                        item!.asset!.asset_model
                                          ? assetName(item)
                                          : $t('asset.label.lack')
                                      }}</b
                                    >
                                    {{ item!.asset!.asset_number }}
                                    <div class="text-xs mt-2">
                                      <b>{{ $t('contracts.label.localization') }}</b>
                                      <div class="flex flex-col">
                                        <span>{{
                                          item!.asset!.branch!.address
                                            ? item!.asset!.branch!.address
                                            : $t('asset.label.lack')
                                        }}</span>
                                        {{
                                          item!.asset!.branch!.path ? item!.asset!.branch!.path : $t('asset.label.lack')
                                        }}
                                        {{ item!.asset!.location != null && item!.asset!.location != '' ? ' - ' + item!.asset!.location : '' }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="text-center flex items-center flex-col mx-4" v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
                                <div class="flex items-center justify-center rounded-full font-bold">
                                  {{ item!.carbon_value ? readableCarbonValue(item!.carbon_value) : '-' }}
                                </div>
                                <div class="text-xs">{{ $t('common.carbon_value') }}</div>
                              </div>
                            </div>
                            <div v-if="visibleDetails.indexOf(item.id) !== -1" class="p-2">
                              <div class="flex items-center justify-between text-sm">
                                <b>{{ $t('contracts.label.note') }}</b>
                                <ZButton
                                  v-if="
                                    contract.can_be_modified && access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)
                                  "
                                  class="h-7"
                                  variant="outline"
                                >
                                  <router-link
                                    :to="{
                                      name: ROUTE.CONTRACT_ACTION_ASSET_ADD_NOTE,
                                      params: { contractId: contract.id, assetId: item.id },
                                    }"
                                  >
                                    {{ $t('common.edit') }}
                                  </router-link>
                                </ZButton>
                              </div>
                              <div class="whitespace-pre-wrap">{{ item.notes ? item.notes : '-' }}</div>
                            </div>
                            <div v-if="visibleDetails.indexOf(item.id) !== -1" class="p-2">
                              <div class="flex items-center justify-between text-sm">
                                <b>{{ $t('files.title') }}</b>
                                <ZButton
                                  v-if="
                                    contract.can_be_modified && access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)
                                  "
                                  class="h-7"
                                  variant="outline"
                                  @click="attachFile(index)"
                                >
                                  {{ $t('asset.command.add_file') }}
                                </ZButton>
                              </div>
                              <div
                                v-if="item.files.length"
                                class="grid gap-4 grid-cols-2 md:grid-cols-4 m-2 lg:grid-cols-5"
                              >
                                <div v-for="(file, index) in item.files" :key="index">
                                  <DropdownMenu :key="index">
                                    <DropdownMenuTrigger :key="index" class="cursor-pointer" as-child>
                                      <div>
                                        <div
                                          class="bg-gray-100 h-[100px] rounded-lg relative overflow-hidden flex items-center justify-center"
                                        >
                                          <img
                                            v-if="isFileImage(file.mime)"
                                            class="absolute w-full h-full object-cover overflow-hidden"
                                            :src="file.url"
                                          />
                                          <div v-else>
                                            <div class="text-center p-1">
                                              <b class="text-xs">{{ file.ext }}</b>
                                              <div class="text-xs line-clamp-2">{{ file.name }}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent class="w-56">
                                      <DropdownMenuGroup>
                                        <DropdownMenuItem @click="getFileDownloadUrl(file.id)">
                                          <span>{{ $t('files.command.download') }}</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuItem
                                          :disabled="
                                            !contract.can_be_modified ||
                                            !access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)
                                          "
                                          @click="detachFile(file.id, item.asset.id, file.pivot_relation_id)"
                                        >
                                          <span>{{ $t('files.command.detach') }}</span>
                                        </DropdownMenuItem>
                                      </DropdownMenuGroup>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                              </div>
                              <div v-else>-</div>
                            </div>
                          </div>

                          <div
                            v-if="actionAssetDataSet.data?.length == 0"
                            class="p-8 text-center flex flex-col gap-2 items-center"
                          >
                            <ExclamationTriangleIcon class="icon lg" />
                            {{ $t('asset.message.data_not_found') }}
                          </div>
                          <Paginator class="px-4" :data="actionAssetDataSet"></Paginator>
                          <div class="p-3">
                            <ZButton
                              v-if="selectedAssets.length && contract.can_be_modified"
                              class="h-7"
                              variant="default"
                              @click="removeAssets(selectedAction!.id!)"
                            >
                              <span>{{ $t('contracts.command.remove_assets') }}</span>
                            </ZButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="histories">
              <ContractChanges class="mt-4" :contract-id="contract!.id" />
            </TabsContent>
          </Tabs>
        </template>
      </div>
    </div>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { Dialogs } from '@/types/Dialogs';
import Navigation from '@/types/Navigation';
import { ZButton } from '@shadcn/components/ui/button';
import { ChevronDownIcon, CogIcon } from '@heroicons/vue/24/outline';
import IconAlert from '@ui/IconAlert.vue';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { ROUTE } from '@/router/routeNames';
import { deleteContract, getContract, toggleContractStatus } from '@/api/contract';
import { getContractedActionAssets, removeAssetsFromAction } from '@/api/contractAsset';
import { deleteContractedAction, getContractedActions } from '@/api/contractAction';
import ContractData = App.Data.ContractData;
import { PRIORITY } from '@/consts';
import symbolsStore from '@/stores/symbols';
import ActionData = App.Data.ActionData;
import ContractActionData = App.Data.ContractActionData;
import { DataSet } from '@/utils/DataSet';
import Paginator from '@ui/Paginator.vue';
import PrettyStatus from '@ui/PrettyStatus.vue';
import { ExclamationTriangleIcon } from '@modules/@heroicons/vue/24/outline';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { HttpStatusCode } from '@modules/axios';
import { toast } from '@shadcn/components/ui/toast';
import ContractAssetData = App.Data.ContractAssetData;
import ContractActionAssetRequestData = App.Data.ContractActionAssetRequestData;
import ContractChanges from '@ui/Contracts/ContractChanges.vue';
import useAccessStore from '@/stores/access';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/components/ui/tabs';
import MountedTeleport from '@ui/MountedTeleport.vue';
import { SYMBOL } from '@/types/symbols';
import DataLoader from '@ui/DataLoader.vue';
import FileAttach from '@ui/File/FileAttach.vue';
import { isFileImage } from '@/utils/isFileImage';
import { detachFile, getFileDownloadUrl } from '@/api';
import useI18n from '@/utils/i18n';
import {KeyValue} from "@/types/Common";

export default defineComponent({
  components: {
    FileAttach,
    DataLoader,
    MountedTeleport,
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
    ContractChanges,
    Checkbox,
    ExclamationTriangleIcon,
    PrettyStatus,
    Paginator,
    DropdownMenuSeparator,
    DropdownMenuItem,
    DropdownMenuGroup,
    DropdownMenuContent,
    DropdownMenuTrigger,
    IconAlert,
    DropdownMenu,
    CogIcon,
    ChevronDownIcon,
    ZButton,
  },

  props: {
    contractId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      navigation: inject('navigation') as Navigation,
    };
  },

  data() {
    return {
      contract: {} as ContractData,
      contract_actions: [] as Array<ActionData>,
      priorities: [] as KeyValue,
      selectedAction: null as ContractActionData | null,
      selectedIndex: null as number | null,
      actionAssetDataSet: {} as DataSet<ContractAssetData, ContractActionAssetRequestData>,
      actionAssetDataStatus: 'loading' as String,
      selectedAssets: [] as Array<number>,
      displayAllNotes: false as boolean,
      loader: null as any,
      visibleDetails: [] as number[],
      filters: {
        search: '',
        page: 1,
        perPage: 8,
      },

      currentTab: 'actions',
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    currentTab() {
      this.$router.push({ hash: '#' + this.currentTab });
    },

    async $route(to: any, from: any) {
      if (to.name.toString() == ROUTE.CONTRACT_DETAILS.toString() && from.name.toString() != ROUTE.CONTRACT_DETAILS.toString()) {
        try {
          await this.getContractData();
          if (this.selectedAction && this.selectedAction.id) {
            const foundAction = this.contract!.contracted_actions!.find(item => item.id == this.selectedAction!.id!);
            if (!foundAction) {
              return;
            }
            this.selectedAction = foundAction;
          } else {
            if (this.contract && this.contract.contracted_actions)
              this.selectedAction = this.contract!.contracted_actions[0];
          }
        } catch (error: any) {
          throw error;
        }
      }
    },

    async selectedAction() {
      if (this.selectedAction && this.selectedAction.id) await this.getActionAssets(this.selectedAction!.id);
    },

    filters: {
      deep: true,
      handler() {
        this.actionAssetDataSet.setParams(this.filters).resetToFirstPage();
      },
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
      if (this.$route.hash) {
        this.currentTab = this.$route.hash.replace('#', '');
      }
      await this.getContractData();
      await this.getActions();
      this.priorities = PRIORITY;
      this.selectedAction =
        this.contract && this.contract.contracted_actions ? this.contract.contracted_actions[0] : null;
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    getFileDownloadUrl,
    isFileImage,
    async getContractData() {
      this.contract = (await getContract(this.contractId).withLoader(this.loader).execute()).data;
    },

    addContractActions() {
      if (!this.contract) {
        return;
      }

      this.$router.push({ name: ROUTE.CONTRACT_ADD_ACTIONS, params: { contractId: this.contract.id } });
    },

    editContractData() {
      if (!this.contract) {
        return;
      }

      this.$router.push({ name: ROUTE.CONTRACT_EDIT, params: { contractId: this.contract.id } });
    },

    deleteContract() {
      this.dialogs
        .confirm(this.$t('contracts.label.deleting_contract'), this.$t('contracts.message.deleting_contract_question'))
        .then(() => {
          if (!this.contract) {
            return;
          }

          deleteContract(this.contract.id!)
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('contracts.message.contract_deleted'),
              });
            });
          this.$router.replace({ name: ROUTE.CONTRACTS_LIST });
        });
    },

    async getActions() {
      const symbols = symbolsStore();
      const rawContractActions = await symbols.get('contract_actions');
      this.contract_actions = Object.values(rawContractActions);
    },

    async getActionAssets(actionId: number) {
      this.actionAssetDataStatus = 'loading';
      if (this.contract && this.contract.id) {
        this.actionAssetDataSet = new DataSet<ContractAssetData, ContractActionAssetRequestData>((query: string) =>
          getContractedActionAssets(this.contract!.id!.toString(), actionId.toString(), this.filters).execute(query),
        ).setParams(this.filters);
        await this.actionAssetDataSet.load();
        this.actionAssetDataStatus = 'loaded';
      }
    },

    async getContractActions() {
      if (this.contract && this.contract.id) {
        await getContractedActions(this.contract!.id?.toString()).execute();
      }
    },

    assignAssets(actionId: number) {
      if (!this.contract || !actionId) {
        return;
      }
      this.$router.push({
        name: ROUTE.CONTRACT_ACTION_ASSIGN_ASSETS,
        params: { contractId: this.contract.id, actionId: actionId },
      });
    },

    editAction(actionId: number) {
      if (!this.contract || !actionId) {
        return;
      }
      this.$router.push({
        name: ROUTE.CONTRACT_ACTION_EDIT,
        params: { contractId: this.contract.id, actionId: actionId },
      });
    },

    deleteAction(actionId: number) {
      this.dialogs
        .confirm(this.$t('contracts.label.deleting_action'), this.$t('contracts.message.deleting_action_question'))
        .then(() => {
          if (!this.contract || !actionId) {
            return;
          }
          deleteContractedAction(this.contract!.id!.toString(), actionId.toString())
            .execute()
            .then(() => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('contracts.message.action_deleted'),
              });
              this.getContractData().then(() => {
                this.selectedAction =
                  this.contract && this.contract.contracted_actions ? this.contract.contracted_actions[0] : null;
              });
            });
        });
    },

    removeAssets(actionId: number) {
      this.dialogs
        .confirm(
          this.$t('contracts.label.deleting_asset_from_action'),
          this.$t('contracts.message.deleting_asset_from_action_question'),
        )
        .then(() => {
          if (!this.contract || !actionId) {
            return;
          }
          removeAssetsFromAction(this.contractId, {
            contracted_action_id: actionId,
            contracted_asset_ids: this.selectedAssets,
          })
            .execute()
            .then(async () => {
              this.toast({
                title: this.$t('toasts.type.saved'),
                description: this.$t('contracts.message.contract_saved'),
              });
              this.selectedAssets = [];
              await this.getActionAssets(actionId);
            })
            .catch(error => {
              if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
                toast({
                  title: this.$t('common.errors.header'),
                  description: this.$t(error.response.data.message),
                  variant: 'destructive',
                });
              }
            });
        });
    },

    getActionData(actionId: number): ActionData {
      const foundItem = this.contract_actions.find(item => item.id == actionId);
      if (!foundItem) return {} as ActionData;
      return foundItem;
    },

    toggleStatus() {
      this.dialogs
        .confirm(
          this.contract.status == SYMBOL.CONTRACT_STATUS.NEW_ORDER
            ? this.$t('contracts.label.canceling_contract')
            : this.$t('contracts.label.activating_contract'),
          this.contract.status == SYMBOL.CONTRACT_STATUS.NEW_ORDER
            ? this.$t('contracts.label.contract_new_order')
            : this.$t('contracts.label.contract_is_draft'),
        )
        .then(() => {
          if (!this.contract) {
            return;
          }
          toggleContractStatus(this.contractId)
            .execute()
            .then(async () => {
              this.toast({
                title: this.$t('toasts.type.saved'),
                description: this.$t('contracts.message.contract_saved'),
              });
              this.selectedAssets = [];
              await this.getContractData();
            })
            .catch(error => {
              if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
                toast({
                  title: this.$t('common.errors.header'),
                  description: this.$t(error.response.data.message),
                  variant: 'destructive',
                });
              }
            });
        });
    },

    toggleSelectedAsset(id: number) {
      const assetChosen = this.selectedAssets.find(assetId => assetId == id);
      if (assetChosen) {
        this.selectedAssets.splice(this.selectedAssets.indexOf(assetChosen), 1);
      } else {
        this.selectedAssets.push(id);
      }
    },

    selectContractedAction(index: number) {
      this.selectedAction = this.contract!.contracted_actions![index];
      this.selectedIndex = index;
    },

    async attachFile(index: number) {
      (this.$refs['attachFileDialog'][index] as typeof FileAttach).show();
    },

    detachFile(id: number, assetId: number, relationId: number) {
      this.dialogs
        .confirm(this.$t('files.label.detach_confirm'), this.$t('files.label.detach_confirm_text'))
        .then(() => {
          detachFile({ destinationId: assetId, destinationType: 'contractAsset', fileId: id, relationId: relationId })
            .execute()
            .then(async () => {
              this.toast({
                title: this.$t('toasts.type.success'),
                description: this.$t('files.message.detached'),
              });
              await this.getActionAssets(this.selectedAction!.id!);
            })
            .catch(() => {
              this.toast({
                title: useI18n.global.t('common.errors.header'),
                description: useI18n.global.t('common.errors.unexpected'),
                variant: 'destructive',
              });
            });
        });
    },

    async refreshAssetFiles() {
      this.toast({
        title: this.$t('common.success.header'),
        description: this.$t('files.message.added'),
      });
      await this.getActionAssets(this.selectedAction!.id!);
    },

    toggleVisibleDetails(id: any) {
      const exist = this.visibleDetails.indexOf(id) !== -1;
      if (exist) {
        this.visibleDetails.splice(this.visibleDetails.indexOf(id), 1);
      } else {
        this.visibleDetails.push(id);
      }
    },

    assetName(item) {
      let name = '' as string;
      if (item!.asset!.asset_model?.name != '') name = item!.asset!.asset_model?.name;
      if (name != '' && item!.asset!.asset_model?.code != '') name += ' / ';
      if (item!.asset!.asset_model?.code != '') name += item!.asset!.asset_model?.code
      if (item.asset!.asset_model?.manufacturer != null && item.asset!.asset_model?.manufacturer != '') name += ' (' + item.asset!.asset_model?.manufacturer + ')';
      return name;
    }
  },
});
</script>
