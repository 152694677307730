<template>
  <div class="p-4 my-4 border rounded flex flex-col gap-4">
    <span>{{ $t('help.info') }}</span>
    <div>
      <a :href="$t('help.pdf_link_operator')" target="_blank" class="inline-block p-2 px-4 rounded border" v-if="access.operator()">
        <span class="flex gap-2 items-center">
          <DocumentArrowDownIcon class="icon sm"/>
          {{ $t('help.button') }}
        </span>
      </a>
      <a :href="$t('help.pdf_link')" target="_blank" class="inline-block p-2 px-4 rounded border" v-else>
        <span class="flex gap-2 items-center">
          <DocumentArrowDownIcon class="icon sm"/>
          {{ $t('help.button') }}
        </span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import {
  DocumentArrowDownIcon,
} from '@heroicons/vue/24/outline';
import { defineComponent } from 'vue';
import useAccessStore from "@/stores/access";

export default defineComponent({
  components: {
    // icons
    DocumentArrowDownIcon,
    // shadcn components
  },

  inject: {
    navigation: { from: 'navigation' },
  },

  data() {
    return {};
  },

  computed: {
    access: () => useAccessStore(),
  },

  methods: {},
});
</script>
