<template>
  <ZDialog :open="showModal" @update:open="openState => (showModal = openState)">
    <ZDialogContent class="max-h-full h-full lg:h-auto overflow-y-scroll lg:min-w-[950px]">
      <Tabs default-value="disc">
        <ZDialogHeader class="mb-5">
          <ZDialogTitle>{{ $t('files.attachComponent.title') }}</ZDialogTitle>
        </ZDialogHeader>
        <div v-if="type !== 'default'" class="mt-4 lg:mt-0 flex lg:flex-col flex-col-reverse">
          <TabsList
            value="media"
            class="no-scrollbar !flex justify-start overflow-x-auto lg:overflow-visible items-center gap-4 lg:gap-0 py-5 w-full"
          >
            <TabsTrigger class="w-full" value="disc" v-if="access.hasFeature(SYMBOL.FEATURES.DOCUMENTS)">
              <div class="relative flex items-center gap-2">
                {{ $t('files.attachComponent.from_disc') }}
              </div>
            </TabsTrigger>
            <TabsTrigger class="w-full" value="media" v-if="access.hasFeature(SYMBOL.FEATURES.DOCUMENTS)">
              <div class="relative flex items-center gap-2">
                {{ $t('files.attachComponent.from_media') }}
              </div>
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="disc">
          <div>
            <FormValidator ref="form">
              <div v-if="filesInfo.length">
                <div v-for="(file, index) in filesInfo" :key="index" class="border relative flex rounded-lg p-4 mb-4">
                  <div
                    class="absolute -left-2 -top-2 bg-primary flex items-center justify-center rounded-full text-xs text-white w-[22px] h-[22px]"
                  >
                    {{ index + 1 }}
                  </div>
                  <ZButton
                    class="h-6 text-xs absolute -right-1 -top-1"
                    variant="destructive"
                    @click.prevent="removeFile(index)"
                    >{{ $t('files.attachComponent.delete') }}</ZButton
                  >
                  <div class="w-[135px] h-[135px] mr-3 hidden lg:block border p-2 rounded-md">
                    <div v-if="file.mime && file.mime.includes('image')">
                      <img :src="file!.url!" class="object-cover w-[135px] h-[119px] overflow-hidden rounded" />
                    </div>
                    <div v-else class="flex items-center h-full justify-center text-xs font-bold">
                      {{ file.ext }}
                    </div>
                  </div>
                  <div class="w-full gap-3 flex flex-col">
                    <div class="flex flex-col lg:flex-row gap-4">
                      <div class="w-full">
                        <Field :symbol="'files.' + index">
                          <Field :symbol="'info.' + index + '.name'">
                            <ZLabel for="model_search">
                              {{ $t('files.label.name') }}
                            </ZLabel>
                            <ZInput id="model_search" v-model="file.name" />
                          </Field>
                        </Field>
                      </div>
                      <div v-if="type !== 'contractAsset'" class="lg:w-[300px]">
                        <Field :symbol="'info.' + index + '.access'">
                          <ZLabel for="model_search">
                            {{ $t('files.label.access') }}
                          </ZLabel>
                          <ZSelect v-model="file.access!">
                            <ZSelectTrigger>
                              <ZSelectValue :placeholder="$t('notification.placeholder')" />
                            </ZSelectTrigger>
                            <ZSelectContent>
                              <ZSelectGroup>
                                <ZSelectItem value="private">{{ $t('files.access.private') }}</ZSelectItem>
                                <ZSelectItem value="branch">{{ $t('files.access.branch') }}</ZSelectItem>
                                <ZSelectItem value="organization">{{ $t('files.access.organization') }}</ZSelectItem>
                              </ZSelectGroup>
                            </ZSelectContent>
                          </ZSelect>
                        </Field>
                      </div>
                    </div>
                    <div>
                      <Field :symbol="'info.' + index + '.description'">
                        <ZLabel for="note">
                          {{ $t('files.label.description') }}
                        </ZLabel>
                        <ZInput id="model_search" v-model="file.description" />
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="max-w-[350px] text-center m-auto my-12">
                  <div class="font-bold">{{ $t('files.attachComponent.select_files') }}</div>
                  <ZInput id="images" class="my-4" multiple type="file" @change="handleFileChange" />
                </div>
              </div>
            </FormValidator>
          </div>
          <ZDialogFooter v-if="filesInfo.length" class="mt-5 flex justify-between">
            <ZInput id="images" class="max-w-[200px]" multiple type="file" @change="handleFileChange" />
            <Action class="w-full" @proceed="uploadFiles">
              {{ $t('files.attachComponent.add_selected_files') }} ({{ filesInfo.length }})</Action
            >
          </ZDialogFooter>
        </TabsContent>
        <TabsContent value="media">
          <div class="lg:flex mb-5 items-center gap-4 justify-between">
            <div class="w-full">
              <ZLabel>{{ $t('files.label.search') }}</ZLabel>
              <ZInput v-model="filesFilters.search" type="search" />
            </div>
            <div class="lg:max-w-[300px]">
              <ZLabel for="technical_condition">
                {{ $t('files.label.extension') }}
              </ZLabel>
              <ZSelect v-model="filesFilters.ext" class="w-full">
                <ZSelectTrigger class="w-full">
                  <div class="flex flex-row gap-2 whitespace-nowrap">
                    <ZSelectValue />
                  </div>
                </ZSelectTrigger>
                <ZSelectContent>
                  <ZSelectGroup>
                    <ZSelectItem value="all">{{ $t('files.extensions.all') }}</ZSelectItem>
                    <ZSelectItem v-for="(ext, key) in extensions" :key="key" :value="key.toString()">
                      {{ $t(ext) }}
                    </ZSelectItem>
                  </ZSelectGroup>
                </ZSelectContent>
              </ZSelect>
            </div>
            <div class="lg:max-w-[300px]">
              <ZLabel for="technical_condition">
                {{ $t('files.label.sort') }}
              </ZLabel>
              <ZSelect v-model="filesFilters.sort" class="w-full">
                <ZSelectTrigger class="w-full">
                  <div class="flex flex-row gap-2 whitespace-nowrap">
                    <ZSelectValue />
                  </div>
                </ZSelectTrigger>
                <ZSelectContent>
                  <ZSelectGroup>
                    <ZSelectItem value="newest">{{ $t('files.sorting.newest') }}</ZSelectItem>
                    <ZSelectItem value="oldest">{{ $t('files.sorting.oldest') }}</ZSelectItem>
                    <ZSelectItem value="asc">{{ $t('files.sorting.asc') }}</ZSelectItem>
                  </ZSelectGroup>
                </ZSelectContent>
              </ZSelect>
            </div>
            <div class="max-w-[200px] whitespace-nowrap mt-5 flex items-center">
              <Checkbox
                id="is_required"
                class="h-6 w-6"
                :checked="filesFilters.mine === 1"
                @update:checked="filesFilters.mine = Number(!filesFilters.mine)"
              />
              <ZLabel for="is_required" class="ml-2 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >{{ $t('files.attachComponent.mine') }}
              </ZLabel>
            </div>
          </div>
          <div class="lg:flex">
            <div v-if="files.data && files.data.length" class="w-full border rounded-lg">
              <ZTable class="w-full">
                <ZTableHeader>
                  <ZTableRow>
                    <ZTableHead class="font-bold text-left"></ZTableHead>
                    <ZTableHead class=""
                      ><b>{{ $t('files.label.file') }}</b></ZTableHead
                    >
                    <ZTableHead class="font-bold text-center">{{ $t('files.label.extension') }}</ZTableHead>
                  </ZTableRow>
                </ZTableHeader>
                <ZTableBody>
                  <ZTableRow
                    v-for="file in files.data"
                    :key="file.id!.toString()"
                    class="cursor-pointer"
                    :class="{ 'bg-gray-100': isSelected(file.id!), 'opacity-50': file.selected }"
                    @click="!file.selected && selectFile(file)"
                  >
                    <ZTableCell class="text-left">
                      <Checkbox class="h-6 w-6" :checked="isSelected(file.id!) || file.selected" />
                    </ZTableCell>
                    <ZTableCell class="text-left w-full">
                      <div class="flex items-center gap-4 justify-start">
                        <div v-if="isFileImage(file.mime)">
                          <img class="w-[40px] h-[40px] object-cover overflow-hidden" :src="file.url!" />
                        </div>
                        <div class="font-medium">{{ file.name }}</div>
                        <div class="text-xs text-gray-400">{{ file.description }}</div>
                      </div>
                    </ZTableCell>
                    <ZTableCell class="text-right">
                      {{ file.ext }}
                    </ZTableCell>
                  </ZTableRow>
                </ZTableBody>
              </ZTable>
              <Paginator class="border-t px-3" :data="files" />
            </div>
            <div v-else class="w-full py-10">
              <InformationCircleIcon class="icon w-[30px] mb-5 m-auto" />
              <h3 class="scroll-m-20 font-semibold text-center">{{ $t('files.message.data_not_found') }}</h3>
            </div>
            <div class="lg:w-[300px] lg:max-w-[300px] mt-4 lg:mt-0">
              <div class="lg:ml-5 border shadow-lg rounded-lg">
                <div class="font-bold text-xs p-4">
                  {{ $t('files.attachComponent.selected_files') }} ({{ selectedFiles.length }})
                </div>
                <div v-if="selectedFiles.length" class="grid-cols-1 divide-y">
                  <div v-for="selected in selectedFiles" :key="selected.id" class="p-3 text-xs">
                    <span class="mr-1 bg-gray-200 text-xs rounded-md py-0.5 px-1.5 truncate">{{ selected.ext }}</span>
                    {{ selected.name }}
                  </div>
                  <div class="p-3 border-t">
                    <ZButton class="h-8 w-full" @click.prevent="attachSelectedFiles">
                      {{ $t('files.attachComponent.attach_files') }}</ZButton
                    >
                  </div>
                </div>
                <div v-else class="text-xs text-center py-4">
                  <InformationCircleIcon class="icon w-[20px] mb-2 m-auto" />
                  {{ $t('files.attachComponent.no_selected_files') }}
                </div>
              </div>
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </ZDialogContent>
  </ZDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@shadcn/components/ui/button';
import { ZDialog, ZDialogContent, ZDialogFooter, ZDialogHeader, ZDialogTitle } from '@shadcn/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/components/ui/tabs';
import {
  ZSelect,
  ZSelectContent,
  ZSelectGroup,
  ZSelectItem,
  ZSelectTrigger,
  ZSelectValue,
} from '@shadcn/components/ui/select';
import { ZInput } from '@shadcn/components/ui/input';
import { ZLabel } from '@shadcn/components/ui/label';
import { attachFile, getFiles, storeNewFile } from '@/api/file';
import { DataSet } from '@/utils/DataSet';
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import { InformationCircleIcon } from '@modules/@heroicons/vue/24/outline';
import Paginator from '@ui/Paginator.vue';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import FileData = App.Data.FileData;
import FileRequestData = App.Data.FileRequestData;
import Field from '@ui/FormField.vue';
import FormValidator from '@ui/FormValidator.vue';
import Action from '@ui/Action.vue';
import symbolsStore from '@/stores/symbols';
import { isFileImage } from '@/utils/isFileImage';
import { inject } from '@modules/vue';
import { toast } from '@shadcn/components/ui/toast';
import { validateMessageTranslation } from '@/utils/validateMessageTranslation';
import useAccessStore from "@/stores/access";
import {SYMBOL} from "@/types/symbols";

interface FileFilterInterface {
  ext?: App.Enums.FileExtensions | string;
  sort: App.Enums.FileSortType | string;
  user_id?: string | number;
  search?: string;
  page?: number | null;
  perPage?: number | null;
  mine?: number | null;
  resourceType?: App.Enums.FileDestinationType | string | null;
  resourceId?: number | null;
  contractedAssetId?: number | null;
};
export default defineComponent({
  components: {
    Action,
    FormValidator,
    Field,
    Checkbox,
    ZTableRow,
    ZTable,
    Paginator,
    ZTableHeader,
    ZTableCell,
    InformationCircleIcon,
    ZTableBody,
    ZTableHead,
    ZSelect,
    ZSelectContent,
    ZSelectGroup,
    ZSelectItem,
    ZSelectTrigger,
    ZSelectValue,
    ZLabel,
    ZInput,
    Tabs,
    ZButton,
    ZDialog,
    ZDialogContent,
    ZDialogFooter,
    ZDialogHeader,
    ZDialogTitle,
    TabsContent,
    TabsList,
    TabsTrigger,
  },

  props: {
    onUpdate: {
      type: Function,
      required: true,
    },

    contractedAssetId: {
      type: [String],
      required: false,
      default: 0,
    },

    type: {
      type: [String],
      required: false,
      default: 'default',
    },

    id: {
      required: false,
      type: Number,
    },
  },

  computed: {
    access: () => useAccessStore(),
  },

  data() {
    return {
      files: {} as DataSet<FileData, FileRequestData>,
      selectedFiles: [] as FileData[],
      showModal: false,
      filesFormData: new FormData() | null,
      filesInfo: [] as FileData[],
      filesFilters: {
        search: '',
        page: 1,
        perPage: 7,
        ext: 'all',
        sort: 'newest',
        resourceType: this.type,
        resourceId: this.id,
        contractedAssetId: parseInt(this.contractedAssetId),
      } as FileFilterInterface,

      extensions: [] as any,
    };
  },

  setup() {
    return {
      toast: inject('toast') as typeof toast,
    };
  },

  watch: {
    showModal() {
      this.showModal && this.getFiles();
    },

    filesFilters: {
      deep: true,
      handler() {
        this.files.setParams(this.resolveFilters() as FileRequestData, true).resetToFirstPage();
      },
    },
  },

  methods: {
    isFileImage,
    show: function () {
      this.showModal = true;
    },

    goBack() {
      this.filesFormData = null;
      this.filesInfo = [];
      this.selectedFiles = [];
      this.showModal = false;
      this.onUpdate();
    },

    handleFileChange(event: any) {
      if (!this.filesInfo.length) {
        this.$refs.form.setErrors([]);
        this.filesFormData = new FormData();
        this.filesInfo = [];
      }
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.filesFormData.append('files[]', file);
        this.filesInfo.push({
          name: file.name?.split('.')?.slice(0, -1)?.join('.'),
          description: '',
          access: this.type === 'contractAsset' ? 'branch' : 'private',
          ext: file.name.split('.').pop(),
          mime: file.type,
          url: URL.createObjectURL(file),
        });
      }
    },

    removeFile(fileIndex: number) {
      const names = this.filesFormData.getAll('files[]');
      this.filesFormData.delete('files[]');
      names.filter((name, index) => index !== fileIndex).forEach(name => this.filesFormData.append('files[]', name));
      this.$refs.form.setErrors([]);
      this.filesInfo.splice(fileIndex, 1);
    },

    async uploadFiles(action: any) {
      try {
        var formData = new FormData();

        if (this.type !== 'default') {
          formData.append('destinationId', this.id);
        }
        if (this.type === 'contractAsset') {
          formData.append('contractedAssetId', this.contractedAssetId);
        }
        formData.append('destinationType', this.type);
        this.filesInfo.map((file, index) => {
          formData.append('info[' + index + '][name]', file.name!);
          formData.append('info[' + index + '][description]', file.description!);
          formData.append('info[' + index + '][access]', file.access!);
        });

        for (const pair of this.filesFormData) {
          formData.append(pair[0], pair[1]);
        }

        await storeNewFile(formData)
          .withForm(this.$refs.form as typeof FormValidator)
          .withLoader(action.loader)
          .execute()
          .then(() => {
            this.goBack();
          })
          .catch(error => {
            const fileLimitError = error.response?.data.errors?.filesLimit?.[0];
            if (fileLimitError) {
              this.toast({
                variant: 'destructive',
                title: this.$t('common.errors.header'),
                description: validateMessageTranslation(fileLimitError, this.$t),
              });
            }
          });
      } catch (errors: any) {}
    },

    async getFiles() {
      const symbols = symbolsStore();
      this.extensions = await symbols.get('file_extensions');

      if (!this.access.hasFeature(SYMBOL.FEATURES.DOCUMENTS)) return;

      this.files = new DataSet<FileData, FileRequestData>((query: string) =>
        getFiles(this.resolveFilters() as FileRequestData).execute(query),
      ).setParams(this.resolveFilters() as FileRequestData);
      await this.files.load();
    },

    async selectFile(file: FileData) {
      if (this.isSelected(file.id!)) {
        this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
      } else {
        this.selectedFiles.push(file);
      }
    },

    isSelected(id: number): boolean {
      return this.selectedFiles.findIndex(item => item.id === id) !== -1;
    },

    attachSelectedFiles() {
      attachFile({
        destinationType: this.type,
        destinationId: this.id,
        contractedAssetId: parseInt(this.contractedAssetId!),
        fileIds: this.selectedFiles.map(item => item.id),
      })
        .execute()
        .then(() => {
          this.goBack();
        })
        .catch(error => {
          const fileLimitError = error.response?.data.errors?.filesLimit?.[0];
          if (fileLimitError) {
            this.toast({
              variant: 'destructive',
              title: this.$t('common.errors.header'),
              description: validateMessageTranslation(fileLimitError, this.$t),
            });
          }
        });
    },

    resolveFilters() {
      if (this.filesFilters.ext === 'all') {
        delete this.filesFilters['ext'];
      }
      return this.filesFilters;
    },
  },
});
</script>
