<template>
  <div class="grid grid-cols-1 lg:grid-cols-5 gap-4">
    <div v-for="(filter, index) in filters" :key="filter.field" :class="{ 'lg:col-span-3': filter.type == 'search' }">
      <div v-if="filtered[index] != undefined">
        <div v-if="filter.type == 'search'">
          <ZLabel>{{ filter.label }}</ZLabel>
          <ZInput v-model="filtered[index].values[0]" type="search" placeholder="" />
        </div>
        <div v-if="filter.type == 'dropdown'">
          <ZLabel>{{ filter.label }}</ZLabel>
          <SelectField v-model="filtered[index].values[0]" :options="filter.items" unselectable searchable />
        </div>
        <div v-if="filter.type == 'multi'">
          <ZLabel>{{ filter.label }}</ZLabel>
          <SelectMultipleField v-model="filtered[index].values[0]" :options="filter.items" />
        </div>
        <div v-if="filter.type == 'date_range'">
          <ZLabel>{{ filter.label }}</ZLabel>
          <DateRangeField v-model:start="filtered[index].values[0]" v-model:end="filtered[index].values[1]" />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <ZLabel>{{ $t('asset.label.reset_filter') }}</ZLabel>
      <ZButton variant="secondary" @click="reset"><TrashIcon class="icon sm"></TrashIcon>{{ $t('asset.command.reset_filter') }}</ZButton>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZInput } from '@shadcn/components/ui/input';
import SelectField from '@ui/SelectField.vue';
import { ZLabel } from '@shadcn/components/ui/label';
import DateRangeField from '@ui/DateRangeField.vue';
import { FilterParam } from '@/utils/DataSet';
import SelectMultipleField from '@ui/SelectMultipleField.vue';
import {TrashIcon} from "@modules/@heroicons/vue/24/outline";
import {ZButton} from "@shadcn/components/ui/button";
import rememberStore from "@/stores/remember";

interface FilterInterface {
  type: string,
  label: string,
  field: any,
  items?: any | undefined,
  default: any,
}

export default defineComponent({
  components: {
    ZButton, TrashIcon,
    SelectMultipleField,
    DateRangeField,
    ZLabel,
    SelectField,
    ZInput,
  },

  props: {
    filters: {
      required: true,
      default: () => [],
      type: Array<FilterInterface>,
    },

    symbol: {
      required: false,
      default: 'filterBarRemember',
      type: String,
    },
  },

  emits: ['updateFilter'],

  data() {
    return {
      filtered: [] as Array<FilterParam>,
      debounce: null as any,
    };
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.filtered = rememberStore().remind(this.symbol, this.resetValues());
      },
    },

    filtered: {
      deep: true,
      handler() {
        if (this.debounce) clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          const filterString = btoa(JSON.stringify(this.filtered));
          rememberStore().memorize(this.symbol, this.filtered);
          this.$emit('updateFilter', filterString, this.filtered);
          this.debounce = null;
        }, 250);
      },
    },
  },

  mounted() {
    this.filtered = rememberStore().remind(this.symbol, this.resetValues());
  },

  methods: {
    reset() {
      this.filtered = this.resetValues();
    },

    resetValues() {
      let filtered = [];
      for (let i in this.filters) {
        if (this.filters[i].type == 'date_range') {
          filtered[i] = {
            field: this.filters[i].field,
            values: [this.filters[i].default?.start ?? '', this.filters[i].default?.end ?? ''],
          };
        } else {
          filtered[i] = { field: this.filters[i].field, values: [this.filters[i].default ?? ''] };
        }
      }

      return filtered;
    }
  },
});
</script>
