import { defineStore } from 'pinia';
import { GroupedKeyValue } from '@/types/Common';
import {getPublicConfig} from '@/api/commonApis';

const publicConfigStore = defineStore('publicConfigStore', {
  state: () => ({
    config: {} as GroupedKeyValue,
    loaded: false as boolean,
  }),

  actions: {
    async get(group: string) {
      if (this.loaded) return this.config[group];
      await this.load();
      return this.config[group];
    },

    async load() {
      const response = await getPublicConfig().execute();
      this.config = response.data;
      this.loaded = true;
    },
  },
});
export default publicConfigStore;
