<template>
  <div class="flex max-w-[80rem] w-full min-h-[400px] m-auto bg-white md:rounded-xl overflow-hidden mb-20">
    <div class="hidden md:block md:w-1/2 relative">
      <img
        class="absolute object-cover w-full h-full right-0 bottom-0 top-0 left-0"
        src="@pub/images/register-background.jpg"
      />
    </div>
    <div class="w-full md:w-1/2 relative pt-10 p-8 md:p-16 flex justify-center items-center">
      <div class="w-full max-w-[30rem]">
        <div class="flex flex-col gap-4">
          <div v-if="!success" class="flex flex-col gap-4">
            <router-link :to="{ name: ROUTE.LOGIN }" class="absolute right-0 top-0 m-3 p-1">
              <ArrowLeftCircleIcon class="icon w-[32px] m-auto" />
            </router-link>
            <h3 class="mb-5 scroll-m-20 text-2xl font-semibold text-center">
              {{ $t('auth.registerOrganization.header') }}
            </h3>
            <FormValidator :errors="errors">
              <div class="flex flex-col gap-4">
                <h3 class="text-md font-bold">{{ $t('auth.registerOrganization.form.organization_header') }}</h3>

                <div class="grid grid-cols-2 gap-4">
                  <FormField symbol="organization.name">
                    <ZLabel>{{ $t('auth.registerOrganization.form.name') }}</ZLabel>
                    <ZInput v-model="form.organization.name" type="text" />
                  </FormField>
                  <FormField symbol="organization.legal_form">
                    <ZLabel>{{ $t('auth.registerOrganization.form.legal_form') }}</ZLabel>
                    <SelectField
                      v-model="form.organization.legal_form"
                      :placeholder="$t('organization.label.select_legal_form')"
                      :options="legalForms"
                      translate="symbols."
                    />
                  </FormField>
                  <FormField symbol="organization.tax_number">
                    <ZLabel>{{ $t('auth.registerOrganization.form.tax_number') }}</ZLabel>
                    <ZInput v-model="form.organization.tax_number" type="text" />
                  </FormField>
                  <FormField symbol="organization.registration_number">
                    <ZLabel>{{ $t('auth.registerOrganization.form.registration_number') }}</ZLabel>
                    <ZInput v-model="form.organization.registration_number" type="text" />
                  </FormField>
                  <FormField symbol="organization.city">
                    <ZLabel>{{ $t('auth.registerOrganization.form.city') }}</ZLabel>
                    <ZInput v-model="form.organization.city" type="text" />
                  </FormField>
                  <FormField symbol="organization.postal">
                    <ZLabel>{{ $t('auth.registerOrganization.form.postal') }}</ZLabel>
                    <ZInput v-model="form.organization.postal" type="text" />
                  </FormField>
                  <FormField symbol="organization.street" class="col-span-2">
                    <ZLabel>{{ $t('auth.registerOrganization.form.street') }}</ZLabel>
                    <ZInput v-model="form.organization.street" type="text" />
                  </FormField>
                  <FormField symbol="organization.building_number">
                    <ZLabel>{{ $t('auth.registerOrganization.form.building_number') }}</ZLabel>
                    <ZInput v-model="form.organization.building_number" type="text" />
                  </FormField>
                  <FormField symbol="organization.apartment_number">
                    <ZLabel>{{ $t('auth.registerOrganization.form.apartment_number') }}</ZLabel>
                    <ZInput v-model="form.organization.apartment_number" type="text" />
                  </FormField>
                  <FormField symbol="organization.country_id">
                    <ZLabel>{{ $t('auth.registerOrganization.form.country') }}</ZLabel>
                    <SelectField
                      v-model="form.organization.country_id"
                      :placeholder="$t('organization.label.select_country')"
                      :options="countries"
                    />
                  </FormField>
                  <FormField symbol="organization.region_id">
                    <ZLabel>{{ $t('auth.registerOrganization.form.region') }}</ZLabel>
                    <SelectField
                      v-model="form.organization.region_id"
                      :placeholder="$t('organization.label.select_region')"
                      :options="regionsForCountry"
                      :disabled="form.organization.country_id == 0"
                    />
                  </FormField>
                </div>
                <h3 class="text-md font-bold">{{ $t('auth.registerOrganization.form.admin_header') }}</h3>
                <div class="grid grid-cols-2 gap-4">
                  <FormField symbol="user.email">
                    <ZLabel>{{ $t('auth.registerOrganization.form.email') }}</ZLabel>
                    <ZInput v-model="form.user.email" type="text" />
                  </FormField>
                  <FormField symbol="user.phone">
                    <ZLabel>{{ $t('auth.registerOrganization.form.phone') }}</ZLabel>
                    <ZInput v-model="form.user.phone" type="text" />
                  </FormField>
                  <FormField symbol="user.first_name">
                    <ZLabel>{{ $t('auth.registerOrganization.form.firstname') }}</ZLabel>
                    <ZInput v-model="form.user.first_name" type="text" />
                  </FormField>
                  <FormField symbol="user.last_name">
                    <ZLabel>{{ $t('auth.registerOrganization.form.lastname') }}</ZLabel>
                    <ZInput v-model="form.user.last_name" type="text" />
                  </FormField>
                  <FormField symbol="user.password" class="col-span-2">
                    <ZLabel>{{ $t('auth.registerOrganization.form.password') }}</ZLabel>
                    <ZInput v-model="form.user.password" type="password" />
                  </FormField>
                  <FormField symbol="user.password_confirmation" class="col-span-2">
                    <ZLabel>{{ $t('auth.registerOrganization.form.password_confirm') }}</ZLabel>
                    <ZInput v-model="form.user.password_confirmation" type="password" />
                  </FormField>
                  <FormField symbol="user.consent" class="col-span-2">
                    <div class="flex items-center space-x-2">
                      <Checkbox
                        id="consent"
                        :checked="form.user.consent"
                        @update:checked="form.user.consent = !form.user.consent"
                      />
                      <ZLabel
                        for="consent"
                        class="text-sm pl-3 leading-4 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        <Translation keypath="auth.registerOrganization.form.consent.message">
                          <template #policy>
                            <a href="" target="_blank"
                              ><b>{{ $t('auth.registerOrganization.form.consent.policy') }}</b></a
                            >
                          </template>
                          <template #rules>
                            <a href="" target="_blank"
                              ><b>{{ $t('auth.registerOrganization.form.consent.rules') }}</b></a
                            >
                          </template>
                        </Translation>
                      </ZLabel>
                    </div>
                  </FormField>
                </div>

                <ZButton @click.prevent="register()">{{ $t('auth.registerOrganization.form.button') }}</ZButton>
              </div>
            </FormValidator>
          </div>
          <div v-else>
            <CheckCircleIcon class="icon lg m-auto mb-8" />
            <h3 class="mb-5 scroll-m-20 text-xl font-semibold text-center">
              <Translation keypath="auth.registerOrganization.success.header">
                <template #name>
                  <b>{{ form.organization.name }}</b>
                </template>
              </Translation>
            </h3>
            <p class="mb-5 scroll-m-20 text-center">
              <Translation keypath="auth.registerOrganization.success.body">
                <template #email>
                  <b>{{ form.user.email }}</b>
                </template>
              </Translation>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { postRegister } from '@/api';
import loaderStore from '@/stores/loader';
import { ZInput } from '@shadcn/components/ui/input';
import { ZButton } from '@shadcn/components/ui/button';
import { ZLabel } from '@shadcn/components/ui/label';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { CheckCircleIcon, ArrowLeftCircleIcon } from '@heroicons/vue/24/outline';
import { Translation } from 'vue-i18n';
import FormValidator from '@ui/FormValidator.vue';
import FormField from '@ui/FormField.vue';
import { HttpStatusCode } from 'axios';
import { ValidationErrors } from '@/types/ValidationErrors';
import SelectField from '@ui/SelectField.vue';
import { getCountries, getRegions } from '@/api/dictionaries';
import { KeyValue } from '@/types/Common';
import CountryWithTranslationData = App.Data.CountryWithTranslationData;
import RegionWithTranslationData = App.Data.RegionWithTranslationData;
import symbolsPreloadStore from "@/stores/symbolsPreload";

export default defineComponent({
  components: {
    SelectField,
    ZInput,
    ZLabel,
    ZButton,
    CheckCircleIcon,
    ArrowLeftCircleIcon,
    Checkbox,
    Translation,
    FormValidator,
    FormField,
  },

  data() {
    return {
      errors: null as null | ValidationErrors,
      success: false,
      countries: {} as KeyValue,
      regions: {} as RegionWithTranslationData[],
      legalForms: {} as KeyValue,
      form: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          password: '',
          password_confirmation: '',
          direct_registration_token: '',
          consent: false,
        },

        organization: {
          tax_number: '',
          registration_number: '',
          legal_form: null as any,
          name: '',
          city: '',
          postal: '',
          street: '',
          country_id: 0 as number,
          region_id: 0 as number,
          building_number: '',
          apartment_number: '',
        },
      },
    };
  },

  computed: {
    regionsForCountry() {
      let options = {} as KeyValue;
      for (let idx in this.regions) {
        if (this.regions[idx].country_id == this.form.organization.country_id) {
          options[this.regions[idx].id] = this.regions[idx].translations[0].translation;
        }
      }
      return options;
    },
  },

  async mounted() {
    const symbols = symbolsPreloadStore();
    this.legalForms = await symbols.get('legal_forms');

    try {
      const response = await getCountries(this.$i18n.locale).execute();
      this.countries = response.data.reduce((accumulator: KeyValue, value: CountryWithTranslationData) => {
        return { ...accumulator, [value.id]: value.translations[0].translation };
      }, {});
    } catch (error: any) {
      throw error;
    }

    try {
      const response = await getRegions(this.$i18n.locale).execute();
      this.regions = response.data;
    } catch (error: any) {
      throw error;
    }
  },

  methods: {
    register() {
      const { startLoading, stopLoading } = loaderStore();
      startLoading();
      postRegister(this.form)
        .then(() => {
          this.success = true;
        })
        .catch(error => {
          if (error?.response?.status == HttpStatusCode.UnprocessableEntity) {
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => stopLoading());
    },
  },
});
</script>
