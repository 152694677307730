<template>
  <DataLoader ref="loader">
    <div class="py-4">
      <div class="grid sm:grid-cols-2 lg:grid-cols-5 sm:gap-4 mt-5">
        <div class="w-full grid lg:col-span-3 gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.contracts_search') }}</ZLabel>
          <ZInput
            v-model="filters.search"
            type="search"
            :placeholder="$t('contracts.label.contracts_search_placeholder')"
          />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel for="technical_condition">
            {{ $t('contracts.label.created_at') }}
          </ZLabel>
          <div class="flex w-full items-center">
            <Popover v-model:open="showDatePicker">
              <PopoverTrigger as-child>
                <ZButton variant="outline" class="w-full font-normal flex items-center justify-start">
                  <div class="flex items-center w-full">
                    <CalendarIcon class="w-[20px] mr-2"/>
                    <span v-if="filters.dateRange!.start && filters!.dateRange!.end" class="leading-3 text-xs">
                    Od: {{ formatDateTime(filters!.dateRange!.start) }} <br/>
                    Do: {{ formatDateTime(filters!.dateRange!.end) }}
                  </span>
                    <span v-else>{{ $t('notification.form.date.button') }}</span>
                  </div>
                  <ChevronDownIcon class="w-[18px] ml-2 text-gray-400"/>
                </ZButton>
              </PopoverTrigger>
              <PopoverContent class="w-auto p-0" align="start">
                <Calendar v-model.range="filters.dateRange" timezone="UTC" :columns="1"/>
              </PopoverContent>
            </Popover>
            <ZButton
              v-if="filters.dateRange!.start! && filters.dateRange!.end"
              variant="ghost"
              @click.prevent="clearDate()"
            >
              <XCircleIcon class="w-[23px]"/>
            </ZButton>
          </div>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.sort') }}</ZLabel>
          <SelectField id="branch_id" v-model="filters.sort" :options="contract_sorting" unselectable/>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 lg:grid-cols-5 sm:gap-4">
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.status') }}</ZLabel>
          <SelectField id="branch_id" v-model="filters.status" :options="contract_states" unselectable/>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.action') }}</ZLabel>
          <SelectField id="branch_id" v-model="filters.action" :options="contract_actions" unselectable/>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.priority') }}</ZLabel>
          <SelectField id="branch_id" v-model="filters.priority" :options="priorities" unselectable/>
        </div>
        <div class="w-full grid gap-2 mb-4 items-end">
          <div class="flex gap-2 items-center mb-2">
            <Checkbox
              id="is_required"
              class="h-6 w-6"
              :checked="filters.mine === 1"
              @update:checked="filters.mine = Number(!filters.mine)"
            />
            <ZLabel for="is_required" class="peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >{{ $t('contracts.label.mine') }}
            </ZLabel>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <ZLabel>{{ $t('asset.label.reset_filter') }}</ZLabel>
          <ZButton variant="secondary" @click="resetFilters"><TrashIcon class="icon sm"></TrashIcon>{{ $t('asset.command.reset_filter') }}</ZButton>
        </div>
      </div>

    </div>
    <Paginator :data="contractDataSet"/>
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead class="text-right">{{ $t('contracts.label.status') }}</ZTableHead>
            <ZTableHead class="whitespace-nowrap">{{ $t('contracts.label.contract_number') }} / {{ $t('contracts.label.priority')}} / {{$t('contracts.label.actions')}}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.assets_actions_count') }}</ZTableHead>
            <ZTableHead class="text-right" v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">{{ $t('common.carbon_value') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.created_at') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.estimated_deadline_at_short') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.author') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.organization') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow
            v-for="(item, index) in contractDataSet.data"
            :key="index"
            class="cursor-pointer"
            @click="details(item.id ?? 0)"
          >
            <ZTableCell class="text-right">
              <PrettyStatus class="whitespace-nowrap" :status="item.status!" :translate="'contract.status.'"/>
            </ZTableCell>
            <ZTableCell class="gap-1 items-center max-w-[20vw]">
              <div>
                <span>{{ item.contract_full_number }}</span
                >/
                <span class="text-xs border inline-block p-1">{{ priorities[item!.priority!] }}</span>
              </div>
              <div v-if="item.contracted_actions!.length" class="w-full mt-1">
                <span v-for="(action, index) in item.contracted_actions" :key="index" class="text-xs"
                >{{ action!.action!.name }}{{ index == item.contracted_actions!.length - 1 ? '' : ', ' }}
                </span>
              </div>
            </ZTableCell>
            <ZTableCell class="text-center"
            ><span>{{ item.contracted_actions_count }}/{{ item.contracted_assets_count }}</span></ZTableCell
            >
            <ZTableCell class="text-right" v-if="access.hasFeature(SYMBOL.FEATURES.CARBON)">
            <span class="font-bold">{{
                item?.carbon_footprint ? readableCarbonValue(item?.carbon_footprint) : '-'
              }}</span>
            </ZTableCell>

            <ZTableCell
            ><span>{{ formatDate(item.created_at!) }}</span></ZTableCell
            >
            <ZTableCell
            ><span v-if="item.estimated_deadline_at">{{ formatDate(item.estimated_deadline_at!) }}</span></ZTableCell
            >
            <ZTableCell
            ><span>{{ item.user_name }}</span></ZTableCell
            >
            <ZTableCell>
              <div class="flex flex-col">
                <b class="font-bold">{{ item.organization!.name }}</b>
              </div>
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="contractDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg"/>
        {{ $t('contracts.message.data_not_found') }}
      </div>
    </div>
    <Paginator :data="contractDataSet"/>
    <router-view></router-view>
    <MountedTeleport to="#toolbox">
      <ZButton
        v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)"
        class="flex gap-2 w-full"
        @click="addNewContract()"
      >
        {{ $t('contracts.command.create_contract') }}
        <PlusCircleIcon class="icon md"/>
      </ZButton>
    </MountedTeleport>
  </DataLoader>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {ZButton} from '@shadcn/components/ui/button';
import MountedTeleport from '@ui/MountedTeleport.vue';
import {ExclamationTriangleIcon, PlusCircleIcon} from '@heroicons/vue/24/outline';
import {ZLabel} from '@shadcn/components/ui/label';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableHead, ZTableHeader, ZTableRow, ZTableCell} from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import {DataSet} from '@/utils/DataSet';
import {ROUTE} from '@/router/routeNames';
import SelectField from '@ui/SelectField.vue';
import {KeyValue} from '@/types/Common';
import {CalendarIcon, ChevronDownIcon, TrashIcon, XCircleIcon} from '@modules/@heroicons/vue/24/outline';
import {Popover, PopoverContent, PopoverTrigger} from '@shadcn/components/ui/popover';
import {Calendar} from '@shadcn/components/ui/calendar';
import ContractData = App.Data.ContractData;
import ContractRequestData = App.Data.ContractRequestData;
import {createContract, getContracts} from '@/api/contract';
import {PRIORITY} from '@/consts';
import symbolsStore from '@/stores/symbols';
import ContractStatus = App.Enums.ContractStatus;
import PrettyStatus from '@ui/PrettyStatus.vue';
import ActionData = App.Data.ActionData;
import {Checkbox} from '@shadcn/components/ui/checkbox';
import useAccessStore from '@/stores/access';
import DataLoader from "@ui/DataLoader.vue";
import ContractDataStoreRequest = App.Data.ContractDataStoreRequest;
import rememberStore from "@/stores/remember";

interface dateRangeInterface {
  start: string;
  end: string;
}

interface ContractRequestInterface {
  search?: string | null;
  page?: number | null;
  page_size?: number | null;
  status?: App.Enums.ContractStatus | null;
  priority?: number | null;
  action?: number | null;
  sort?: string | null;
  dateRange?: dateRangeInterface;
  mine?: number | null;
}

export default defineComponent({
  components: {
    TrashIcon,
    DataLoader,
    Checkbox,
    PrettyStatus,
    ChevronDownIcon,
    CalendarIcon,
    XCircleIcon,
    SelectField,
    ZTableHead,
    ZTableBody,
    ZTable,
    Paginator,
    ZTableHeader,
    ZTableRow,
    ExclamationTriangleIcon,
    PlusCircleIcon,
    MountedTeleport,
    ZButton,
    ZLabel,
    ZInput,
    Popover,
    PopoverTrigger,
    Calendar,
    PopoverContent,
    ZTableCell,
  },

  data() {
    return {
      contractDataSet: {} as DataSet<ContractData, ContractRequestData>,
      contract_states: {} as KeyValue,
      contract_sorting: {} as KeyValue,
      contract_actions: {} as KeyValue,
      statuses: {} as KeyValue,
      priorities: {} as KeyValue,
      loader: null as any,
      actions: {} as KeyValue,
      showDatePicker: false,
      filters: rememberStore().remind('contract.filters', {
        search: '',
        page: 1,
        page_size: 15,
        status: '' as ContractStatus,
        action: null,
        priority: null,
        sort: '',
        mine: 0 as number,
        dateRange: {
          start: '',
          end: '',
        },
      }),
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.showDatePicker = false;
        if (this.filters.status == undefined) {
          delete this.filters['status'];
        }
        if (this.filters.sort == undefined) {
          delete this.filters['sort'];
        }
        if (this.filters.action == null) {
          delete this.filters['action'];
        }
        if (this.filters.priority == null) {
          delete this.filters['priority'];
        }
        this.contractDataSet
          .setParams(
            {
              ...(this.filters as ContractRequestData),
              dateRange: {
                start: this.filters!.dateRange!.start && new Date(this.filters!.dateRange!.start).toISOString(),
                end: this.filters!.dateRange!.end && new Date(this.filters!.dateRange!.end).toISOString(),
              } as dateRangeInterface | any,
            },
            true,
          )
          .resetToFirstPage();
      },
    },
  },

  async mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

    if (this.filters.status == undefined) {
      delete this.filters['status'];
    }
    if (this.filters.priority == null) {
      delete this.filters['priority'];
    }

    this.contractDataSet = new DataSet<ContractData, ContractRequestData>((query: string) =>
      getContracts({
        ...(this.filters as ContractRequestData),
        dateRange: {
          start: this.filters!.dateRange!.start && new Date(this.filters!.dateRange!.start).toISOString(),
          end: this.filters!.dateRange!.end && new Date(this.filters!.dateRange!.end).toISOString(),
        } as dateRangeInterface | any,
      }).withLoader(this.loader).execute(query),
      () => {
        rememberStore().memorize('contract.filters', {...this.filters, page: this.contractDataSet.meta?.current_page, page_size: this.contractDataSet.meta?.per_page})
      });
    await this.contractDataSet.load();
    for (const [key, value] of Object.entries(PRIORITY)) {
      this.priorities[key] = this.$t('contracts.priority.' + value);
    }
    await this.getStates();
    await this.getActions();
    await this.getSorting();
  },

  methods: {
    router() {
      return this.$router;
    },

    details(id: number) {
      this.$router.push({name: ROUTE.CONTRACT_DETAILS, params: {contractId: id}});
    },

    async getStates() {
      const symbols = symbolsStore();
      const states = await symbols.get('contract_states');
      for (const [key, value] of Object.entries(states)) {
        this.contract_states[key] = this.$t(value);
      }
    },

    async getSorting() {
      const symbols = symbolsStore();
      const sorting = await symbols.get('contract_sorting');
      for (const [key, value] of Object.entries(sorting)) {
        this.contract_sorting[key] = this.$t(value);
      }
    },

    async getActions() {
      const symbols = symbolsStore();
      const actions = await symbols.get('contract_actions');
      this.contract_actions = actions.reduce((accumulator: KeyValue, value: ActionData) => {
        return {...accumulator, [String(value.id)]: value.name};
      }, {});
    },

    clearDate() {
      this.filters.dateRange = {
        start: '',
        end: '',
      } as dateRangeInterface;
    },

    addNewContract() {
      let contract = {
        priority: 4,
      } as ContractDataStoreRequest;

      createContract(contract)
        .execute()
        .then(response => {
          this.$router.push({ name: this.ROUTE.CONTRACT_DETAILS, params: { contractId: response.data.new_id } });

          this.toast({
            title: this.$t('toasts.type.success'),
            description: this.$t('contracts.message.asset_created'),
          });
        });
    },

    resetFilters() {
      this.filters = {
        search: '',
        page: 1,
        page_size: 15,
        status: '' as ContractStatus,
        action: null,
        priority: null,
        sort: '',
        mine: 0 as number,
        dateRange: {
          start: '',
          end: '',
        },
      }
    }
  },
});
</script>
