<template>
  <DataLoader ref="loader">
    <template v-if="user.organizations">
      <div class="py-4 flex flex-col">
        <IconAlert v-if="user.changes_requested_status" class="my-2" type="warning">
          <div class="flex flex-row justify-between items-center w-full">
            <span>{{ $t('userAccount.label.user_requested_changes') }}</span>
            <ZButton variant="outline" @click="editUser()">{{ $t('userAccount.command.verify_changes') }}</ZButton>
          </div>
        </IconAlert>
        <div class="flex flex-col lg:flex-row gap-4 w-full border rounded border-green-600 p-4 mb-4">
          <div class="flex flex-col flex-1">
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.account_type') }}</span>
              <span class="i-value badge" :class="user.account_type ?? ''">{{
                user.account_type ? $t('symbols.accountType.' + user.account_type) : '-'
              }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.account_state') }}</span>
              <span class="i-value badge" :class="user.state ?? ''">{{
                user.state ? $t('symbols.accountState.' + user.state) : '-'
              }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label w-1/12">{{ $t('userAccount.label.email') }}</span>
              <span class="i-value">{{ user.email ?? '-' }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.first_name') }}</span>
              <span class="i-value">{{ user.first_name ?? '-' }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.last_name') }}</span>
              <span class="i-value">{{ user.last_name ?? '-' }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.position') }}</span>
              <span class="i-value">{{ user.position ?? '-' }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.phone') }}</span>
              <span class="i-value">{{ user.phone ?? '-' }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.allowed_ips') }}</span>
              <span class="i-value">{{
                user.allowed_ips && user.allowed_ips != '' ? user.allowed_ips : $t('userAccount.label.no_ip_limit')
              }}</span>
            </div>
            <div class="i-block horizontal">
              <span class="i-label">{{ $t('userAccount.label.password_health') }}</span>
              <span class="i-value badge" :class="{ warning: user.password_reset_required }">{{
                user.password_reset_required
                  ? $t('userAccount.label.password_needs_change')
                  : $t('userAccount.label.password_is_ok')
              }}</span>
            </div>
          </div>
          <div class="flex-1">
            <div v-if="user.account_type == SYMBOL.ACCOUNT_TYPE.CUSTOMER">
              <div class="py-1 text-sm opacity-60">{{ $t('userAccount.label.user_assigned_to') }}</div>
              <div class="p-4 align-middle flex flex-col md:flex-row gap-2 md:items-center bg-gray-100 rounded-lg">
                <div class="flex items-center">
                  <div>
                    <BuildingOfficeIcon class="icon md mr-1" />
                  </div>
                  <div v-if="user.organizations.length > 0" class="flex flex-col">
                    <b>{{ user.organization_name ?? '-' }}</b>
                  </div>
                  <div v-else>
                    {{ $t('userAccount.message.no_organization_assigned') }}
                  </div>
                </div>
                <div v-if="access.operator()" class="flex-1 text-right ml-1">
                  <ZButton v-if="!changingOrganization" variant="outline" @click.prevent="changingOrganization = true"
                    >{{ $t('userAccount.command.manage_organization') }}
                  </ZButton>
                  <div v-else class="border p-2 flex gap-2 flex-col">
                    <SelectField
                      v-model="changedOrganizationId"
                      :placeholder="$t('userAccount.label.select_organization')"
                      :options="organizations"
                      class="flex-1"
                    ></SelectField>
                    <div class="flex flex-row gap-2">
                      <ZButton
                        :disabled="changedOrganizationId == '' || userOrganizations[changedOrganizationId]"
                        @click="addOrganization()"
                        >{{ $t('userAccount.command.add_organization') }}
                      </ZButton>
                      <ZButton
                        :disabled="changedOrganizationId == '' || !userOrganizations[changedOrganizationId]"
                        @click="removeOrganization()"
                        >{{ $t('userAccount.command.remove_organization') }}
                      </ZButton>
                      <ZButton variant="outline" @click.prevent="changingOrganization = false"
                        >{{ $t('common.cancel') }}
                      </ZButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-1 pt-3 text-sm opacity-60">{{ $t('userAccount.label.2fa_auth') }}:</div>

            <div
              v-if="user.two_factor_status == 'enabled'"
              class="p-4 align-middle flex flex-row gap-2 items-center justify-between bg-green-500 text-white rounded-lg p-2 text-sm"
            >
              <div class="flex flex-row gap-2 items items-center">
                <ShieldCheckIcon class="icon lg" />
                {{ $t('userAccount.label.2fa_enabled') }}
              </div>
              <ZButton as-child>
                <router-link :to="{ name: ROUTE.USER_ACCOUNT_MANAGE_2FA }">
                  {{ $t('userAccount.command.manage_2fa') }}
                </router-link>
              </ZButton>
            </div>

            <div
              v-else
              class="p-4 align-middle flex flex-row gap-2 items-center bg-yellow-500 text-white rounded-lg text-sm"
            >
              <ShieldExclamationIcon class="icon lg" />
              {{ $t('userAccount.label.2fa_disabled') }}
            </div>

            <div v-if="user.account_type == SYMBOL.ACCOUNT_TYPE.CUSTOMER && editable">
              <div class="py-1 pt-3 text-sm opacity-60">{{ $t('userAccount.label.organization_notes') }}:</div>
              <div class="p-4 align-middle flex flex-row gap-2 items-center bg-gray-100 rounded-lg text-sm">
                {{ user.organization_notes }}
              </div>
            </div>

            <div v-if="access.operator()" class="py-1 pt-3 text-sm opacity-60">
              {{ $t('userAccount.label.operator_notes') }}:
            </div>
            <div
              v-if="access.operator()"
              class="p-4 align-middle flex flex-row gap-2 items-center bg-gray-100 rounded-lg text-sm"
            >
              {{ user.operator_notes }}
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div v-if="user.account_type == SYMBOL.ACCOUNT_TYPE.OPERATOR" class="border p-8 rounded">
            <h3 class="font-semibold pb-8">{{ $t('userAccount.label.operator_permissions') }}</h3>
            <div class="grid grid-cols-4 gap-4">
              <div class="flex items-center gap-2 text-sm col-span-4 bg-gray-100 p-4 rounded-lg">
                <ZSwitch
                  id="super"
                  :checked="hasPermission(SYMBOL.PERMISSION.SYS_ADMIN)"
                  @update:checked="togglePermission(SYMBOL.PERMISSION.SYS_ADMIN)"
                />
                <label for="super">
                  <b>{{ $t('userAccount.label.permission_sys_admin') }}</b>
                  {{ $t('userAccount.message.permission_sys_admin') }}
                </label>
              </div>
            </div>
            <div v-if="!hasPermission(SYMBOL.PERMISSION.SYS_ADMIN)" class="grid grid-cols-3 gap-4">
              <div class="flex items-center gap-2 text-sm p-4">
                <ZSwitch
                  id="dict"
                  :checked="hasPermission(SYMBOL.PERMISSION.SYS_DICTIONARY_MANAGER)"
                  @update:checked="togglePermission(SYMBOL.PERMISSION.SYS_DICTIONARY_MANAGER)"
                />
                <label for="dict">{{ $t('userAccount.label.permission_dictionary_manager') }}</label>
              </div>
              <div class="flex items-center gap-2 text-sm p-4">
                <ZSwitch
                  id="dict"
                  :checked="hasPermission(SYMBOL.PERMISSION.SYS_CATEGORY_MANAGER)"
                  @update:checked="togglePermission(SYMBOL.PERMISSION.SYS_CATEGORY_MANAGER)"
                />
                <label for="dict">{{ $t('userAccount.label.permission_category_manager') }}</label>
              </div>
            </div>
          </div>
          <div class="border p-8 rounded flex gap-4" v-if="user.organizations.length == 0 && user.account_type != SYMBOL.ACCOUNT_TYPE.OPERATOR">
            <ExclamationTriangleIcon class="icon md"/>
            {{ $t('userAccount.message.no_assigned_organizations') }}
          </div>
          <div class="border p-8 rounded" v-else-if="user.account_type != SYMBOL.ACCOUNT_TYPE.OPERATOR">
            <div class="flex justify-between items-center">
              <h3 class="font-semibold pb-8">{{ $t('userAccount.label.permission_customer') }}</h3>
              <div v-if="access.operator()" class="flex flex-col md:flex-row gap-2 items-center pb-8 w-[300px]">
                {{ $t('userAccount.label.organization') }}:
                <SelectField v-model="currentOrganizationId" :options="userOrganizations" />
              </div>
            </div>
            <div class="flex items-center gap-2 text-sm col-span-4 bg-gray-100 p-4 rounded-lg">
              <ZSwitch
                id="adm"
                :checked="hasPermission(SYMBOL.PERMISSION.ORG_ADMIN)"
                @update:checked="togglePermission(SYMBOL.PERMISSION.ORG_ADMIN)"
              />
              <label for="adm">
                <b>{{ $t('userAccount.label.permission_org_admin') }}</b>
                {{ $t('userAccount.message.permission_org_admin') }}
              </label>
            </div>

            <div v-if="!hasPermission(SYMBOL.PERMISSION.ORG_ADMIN)" class="overflow-y-auto w-full">
              <div
                class="mt-4 bg-gray-100 rounded-lg p-2 items-center whitespace-nowrap overflow-x-auto grid custom-grid gap-4 w-max md:w-auto"
              >
                <div class="col-span-2 font-semibold flex flex-row gap-2">
                  <MapPinIcon class="icon sm" />
                  {{ $t('userAccount.label.branches') }}
                </div>
                <div class="text-center font-semibold">{{ $t('userAccount.label.permission_branch_view') }}</div>
                <div class="text-center font-semibold">{{ $t('userAccount.label.permission_branch_manage') }}</div>
                <div class="text-center font-semibold">{{ $t('userAccount.label.permission_branch_finance') }}</div>
                <div class="text-center font-semibold">{{ $t('userAccount.label.permission_branch_reports') }}</div>
              </div>

              <div
                v-for="(item, index) in branches"
                :key="index"
                class="group gap-4 pl-2 border-b rounded py-1 hover:bg-accent items-center grid custom-grid w-max md:w-auto"
                :class="{
                  'mt-2': item.branch_type == 'location',
                }"
              >
                <div
                  class="flex gap-2 py-1 col-span-2"
                  :style="{ 'padding-left': (item.depth != null ? (item.depth - 1) * 20 : 0) + 'px' }"
                >
                  <MapPinIcon v-if="item.branch_type == 'location'" class="icon sm" />
                  <BriefcaseIcon v-else class="icon sm" />
                  <div class="leading-4">
                    {{ item.name }}
                    <div v-if="item.branch_type == 'location'">
                      <span class="text-xs"
                        >{{ item.postal }} {{ item.city }}, {{ item.street }} {{ item.building_number }}
                        {{ item.apartment_number ?? '' }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <ZSwitch
                    v-if="!branchHasAnyPermission(item.id!.toString()) && !isBranchLockedByAny(item.id!.toString())"
                    :checked="hasPermission(SYMBOL.PERMISSION.BRANCH_VIEW, item.id!.toString())"
                    @update:checked="togglePermission(SYMBOL.PERMISSION.BRANCH_VIEW, item.id!.toString())"
                  />
                  <div
                    v-else-if="branchHasAnyPermission(item.id!.toString()) || isBranchLockedByAny(item.id!.toString())"
                    class="flex justify-center"
                  >
                    <CheckCircleIcon class="icon md text-primary" />
                  </div>
                </div>

                <div class="text-center">
                  <ZSwitch
                    v-if="!isBranchLocked(SYMBOL.PERMISSION.BRANCH_MANAGE, item.id!.toString())"
                    :checked="hasPermission(SYMBOL.PERMISSION.BRANCH_MANAGE, item.id!.toString())"
                    @update:checked="togglePermission(SYMBOL.PERMISSION.BRANCH_MANAGE, item.id!.toString())"
                  />
                  <div v-else class="flex justify-center">
                    <CheckCircleIcon class="icon md text-primary" />
                  </div>
                </div>

                <div class="text-center">
                  <ZSwitch
                    v-if="!isBranchLocked(SYMBOL.PERMISSION.BRANCH_FINANCE, item.id!.toString())"
                    :disabled="!currentOrganization || currentOrganization.feature_finance != '1'"
                    :checked="hasPermission(SYMBOL.PERMISSION.BRANCH_FINANCE, item.id!.toString())"
                    @update:checked="togglePermission(SYMBOL.PERMISSION.BRANCH_FINANCE, item.id!.toString())"
                  />
                  <div v-else class="flex justify-center">
                    <CheckCircleIcon class="icon md text-primary" />
                  </div>
                </div>

                <div class="text-center">
                  <ZSwitch
                    v-if="!isBranchLocked(SYMBOL.PERMISSION.BRANCH_REPORT, item.id!.toString())"
                    :checked="hasPermission(SYMBOL.PERMISSION.BRANCH_REPORT, item.id!.toString())"
                    @update:checked="togglePermission(SYMBOL.PERMISSION.BRANCH_REPORT, item.id!.toString())"
                  />
                  <div v-else class="flex justify-center">
                    <CheckCircleIcon class="icon md text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="access.operator()" class="border rounded p-8">
            <h3 class="font-semibold pb-8">{{ $t('userAccount.label.user_activity') }}</h3>
            <Paginator :data="activityDataSet" />
            <div class="border rounded">
              <ZTable class="w-full">
                <ZTableHeader>
                  <ZTableRow>
                    <ZTableHead>{{ $t('userAccount.label.activity_time') }}</ZTableHead>
                    <ZTableHead>{{ $t('userAccount.label.activity_event') }}</ZTableHead>
                    <ZTableHead>{{ $t('userAccount.label.activity_subject') }}</ZTableHead>
                    <ZTableHead>{{ $t('userAccount.label.activity_details') }}</ZTableHead>
                  </ZTableRow>
                </ZTableHeader>
                <ZTableBody>
                  <ZTableRow v-for="(item, index) in activityDataSet.data" :key="index" class="cursor-pointer">
                    <ZTableCell>{{ item.created_at }}</ZTableCell>
                    <ZTableCell> {{ $t('activities.' + item.event) }}<br /></ZTableCell>
                    <ZTableCell>
                      {{ item.subject.name }}
                      <br />
                      <small>{{ item.subject.breadcrumbs }}</small>
                    </ZTableCell>
                    <ZTableCell>
                      <div class="property-block">
                        <template v-if="item.properties.old && item.properties.attributes">
                          <div v-for="(value, field) in item.properties.old" :key="field" class="property">
                            <span class="field">{{ field }}</span>
                            <div class="value">
                              <span class="before">{{ value }}</span>
                              <span class="after">{{ item.properties.attributes[field] }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="item.properties.attributes">
                          <div v-for="(value, field) in item.properties.attributes" :key="field" class="property">
                            <span class="field">{{ field }}</span>
                            <div class="value">
                              <span class="after">{{ value }}</span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div v-for="(value, field) in item.properties" :key="field" class="property">
                            <span class="field">{{ field }}</span>
                            <div class="value">
                              <span class="after">{{ value }}</span>
                            </div>
                          </div>
                        </template>
                      </div>
                    </ZTableCell>
                  </ZTableRow>
                </ZTableBody>
              </ZTable>
              <div v-if="activityDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
                <ExclamationTriangleIcon class="icon lg" />
                {{ $t('userAccount.message.data_not_found') }}
              </div>
            </div>
            <Paginator :data="activityDataSet" />
          </div>
        </div>

        <MountedTeleport to="#toolbox">
          <DropdownMenu v-if="editable">
            <DropdownMenuTrigger as-child>
              <ZButton class="w-full">
                <BuildingOfficeIcon class="icon sm" />
                <span class="ml-2">
                  {{ $t('userAccount.command.manage') }}
                </span>
                <ChevronDownIcon class="icon xs ml-2" />
              </ZButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56">
              <DropdownMenuGroup>
                <DropdownMenuItem @click="editUser()">
                  <span>{{ $t('userAccount.command.edit_user_data') }}</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="changeUserPassword()">
                  <span>{{ $t('userAccount.command.change_user_password') }}</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="loginAsUser()">
                  <span>{{ $t('userAccount.command.login_as_user') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  v-if="user.state == SYMBOL.ACCOUNT_STATE.NOT_ACTIVATED"
                  @click="setState(SYMBOL.ACCOUNT_STATE.ACTIVE)"
                >
                  <span>{{ $t('userAccount.command.set_user_as_active') }}</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  v-if="user.state == SYMBOL.ACCOUNT_STATE.ACTIVE"
                  @click="setState(SYMBOL.ACCOUNT_STATE.BLOCKED)"
                >
                  <span>{{ $t('userAccount.command.set_user_as_blocked') }}</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  v-if="user.state == SYMBOL.ACCOUNT_STATE.BLOCKED"
                  @click="setState(SYMBOL.ACCOUNT_STATE.ACTIVE)"
                >
                  <span>{{ $t('userAccount.command.set_user_as_unblocked') }}</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem :disabled="!user.can_be_deleted" @click="requestUserAccountDelete">
                  <span>{{ $t('userAccount.command.delete_user') }}</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </MountedTeleport>
        <router-view></router-view>
      </div>
    </template>
  </DataLoader>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Navigation from '@/types/Navigation';
import UserAccountData = App.Data.UserAccountData;
import { Dialogs } from '@/types/Dialogs';
import {
  getUserAccount,
  deleteUserAccount,
  getUserActivities,
  setUserState,
  setUserOrganization,
  loginAsTemporaryUser,
  grantPermission,
  getPermissions,
  revokePermission,
  getBranches,
  getOrganizationsCompact,
  removeUserOrganization, updateUserPassword,
} from '@/api';
import {
  BuildingOfficeIcon,
  MapPinIcon,
  BriefcaseIcon,
  ChevronDownIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
  CheckCircleIcon,
} from '@heroicons/vue/24/outline';
import { ZButton } from '@shadcn/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { ZSwitch } from '@shadcn/components/ui/switch';
import IconAlert from '@ui/IconAlert.vue';
import { ROUTE } from '@/router/routeNames';
import MountedTeleport from '@ui/MountedTeleport.vue';
import { DataSet } from '@/utils/DataSet';
import UserTrackedActivityData = App.Data.UserTrackedActivityData;
import { ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow } from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import { KeyValue } from '@/types/Common';
import OrganizationData = App.Data.OrganizationData;
import SelectField from '@ui/SelectField.vue';
import useUserStore from '@/stores/auth';
import Cookies from 'js-cookie';
import { AUTH_COOKIE_KEY } from '@/consts';
import BranchData = App.Data.BranchData;
import OrganizationCompactData = App.Data.OrganizationCompactData;
import SimpleSearchData = App.Data.SimpleSearchData;
import DataLoader from '@ui/DataLoader.vue';
import useAccessStore from '@/stores/access';
import { SYMBOL } from '@/types/symbols';
import FormValidator from "@ui/FormValidator.vue";

export default defineComponent({
  components: {
    DataLoader,
    SelectField,
    Paginator,
    IconAlert,
    MountedTeleport,
    // icons
    MapPinIcon,
    BriefcaseIcon,
    BuildingOfficeIcon,
    ChevronDownIcon,
    ShieldExclamationIcon,
    ShieldCheckIcon,
    ExclamationTriangleIcon,
    CheckCircleIcon,
    // shadcn components
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    ZSwitch,
    ZButton,
    ZTableHeader,
    ZTable,
    ZTableRow,
    ZTableBody,
    ZTableHead,
    ZTableCell,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      navigation: inject('navigation') as Navigation,
      dialogs: inject('dialogs') as Dialogs,
      toast: inject('toast') as (props: any) => void,
      stats: inject('stats') as { updateStats: () => Promise<void> },
    };
  },

  data() {
    return {
      user: {} as UserAccountData,
      usersDataSet: {} as DataSet<OrganizationData, SimpleSearchData>,
      organizations: {} as KeyValue,
      userOrganizations: {} as KeyValue,
      currentOrganizationId: null as null | string,
      currentOrganization: null as null | OrganizationData,
      changingOrganization: false as boolean,
      changedOrganizationId: '' as string,
      branches: [] as BranchData[],
      activityDataSet: {} as DataSet<UserTrackedActivityData, SimpleSearchData>,
      permissions: {} as any,
      parentBranches: null as Map<string, string[]> | null,
      loader: null as any,
      editable: false,
    };
  },

  computed: {
    access: () => useAccessStore(),
  },

  watch: {
    async currentOrganizationId() {
      if (this.currentOrganizationId != null) {
        const branchesResponse = await getBranches(this.currentOrganizationId!.toString())
          .withLoader(this.loader)
          .execute();
        this.branches = branchesResponse.data;
        this.reloadPermissions();

        for (let organization: OrganizationData of this.user.organizations) {
          if (organization.id == this.currentOrganizationId) {
            this.currentOrganization = organization;
            break;
          }
        }
      }
    },

    $route(to: any) {
      if (to.name == ROUTE.USER_ACCOUNT_DETAILS) {
        this.reload();
      }
    },
  },

  async mounted() {
    try {
      this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

      const organizationsResponse = await getOrganizationsCompact().withLoader(this.loader).execute();
      this.organizations = organizationsResponse.data.reduce(
        (accumulator: KeyValue, value: OrganizationCompactData) => {
          return { ...accumulator, [value.id]: value.name };
        },
        {},
      );
    } catch (error: any) {}

    await this.reload();
  },

  methods: {
    async reload() {
      try {
        const response = await getUserAccount(this.userId).withLoader(this.loader).execute();
        this.user = response.data;

        if (this.user.organizations?.length == 1 || this.access.operator(this.SYMBOL.PERMISSION.SYS_ADMIN))
          this.editable = true;

        this.navigation.setPanelTitle(this.user.first_name + ' ' + this.user.last_name);

        this.userOrganizations = this.user!.organizations!.reduce<KeyValue>(
          (accumulator: KeyValue, value: OrganizationData) => {
            return { ...accumulator, [String(value.id)]: value.name };
          },
          {},
        );

        if (this.user.organizations != null && this.user.organizations.length > 0) {
          this.currentOrganizationId = this.user.organizations[0]!.id.toString();
        }

        if (this.access.operator()) {
          this.activityDataSet = new DataSet<UserTrackedActivityData, SimpleSearchData>((query: string) =>
            getUserActivities(this.user.id!.toString()).withLoader(this.loader).execute(query),
          );
          await this.activityDataSet.load();
        }

        if (this.currentOrganizationId) {
          const branchesResponse = await getBranches(this.currentOrganizationId!.toString())
            .withLoader(this.loader)
            .execute();
          this.branches = branchesResponse.data;

          for (let organization: OrganizationData of this.user.organizations) {
            if (organization.id == this.currentOrganizationId) {
              this.currentOrganization = organization;
              break;
            }
          }
        }

        this.generateParents();

        await this.reloadPermissions();

        await this.stats.updateStats();

      } catch (error: any) {
        throw error;
      }
    },

    generateParents() {
      let parentBranches = new Map<string, string[]>();

      let i: string;
      for (i in this.branches) {
        let branch = this.branches[i];
        let parents = [] as string[];

        if (branch.parent_branch_id) {
          let parentParents = parentBranches.get(branch.parent_branch_id.toString());
          parents = parentParents
            ? [...parentParents, branch.parent_branch_id.toString()]
            : [branch.parent_branch_id.toString()];
        } else parents = [];

        parentBranches.set(branch.id!.toString(), parents);
      }

      this.parentBranches = parentBranches;
    },

    async reloadPermissions() {
      this.permissions = {};
      const permissionsResponse = await getPermissions(this.userId, this.currentOrganizationId!)
        .withLoader(this.loader)
        .execute();
      let i: string;
      for (i in permissionsResponse.data) {
        let perm = permissionsResponse.data[i];
        if (this.permissions[perm.symbol] == undefined) this.permissions[perm.symbol] = {};
        this.permissions[perm.symbol][perm.branch_id ?? 'all'] = perm.id;
      }
    },

    hasPermission(symbol: string, branchId: string | null = null) {
      if (branchId == undefined) branchId = 'all';

      if (this.permissions[symbol] && this.permissions[symbol][branchId]) return true;
      return false;
    },

    branchHasAnyPermission(branchId: string) {
      if (
        this.permissions[SYMBOL.PERMISSION.BRANCH_MANAGE] &&
        this.permissions[SYMBOL.PERMISSION.BRANCH_MANAGE][branchId]
      )
        return true;
      if (
        this.permissions[SYMBOL.PERMISSION.BRANCH_FINANCE] &&
        this.permissions[SYMBOL.PERMISSION.BRANCH_FINANCE][branchId]
      )
        return true;
      if (
        this.permissions[SYMBOL.PERMISSION.BRANCH_REPORT] &&
        this.permissions[SYMBOL.PERMISSION.BRANCH_REPORT][branchId]
      )
        return true;
      return false;
    },

    isBranchLocked(symbol: string, branchId: string) {
      if (!this.parentBranches) return true;

      let parents = this.parentBranches.get(branchId);
      if (parents)
        for (let parentBranch of parents) {
          if (this.permissions[symbol] && this.permissions[symbol][parentBranch]) return true;
        }

      return false;
    },

    isBranchLockedByAny(branchId: string) {
      if (!this.parentBranches) return true;

      let parents = this.parentBranches.get(branchId);
      if (parents)
        for (let parentBranch of parents) {
          if (
            this.permissions[SYMBOL.PERMISSION.BRANCH_VIEW] &&
            this.permissions[SYMBOL.PERMISSION.BRANCH_VIEW][parentBranch]
          )
            return true;
          if (
            this.permissions[SYMBOL.PERMISSION.BRANCH_MANAGE] &&
            this.permissions[SYMBOL.PERMISSION.BRANCH_MANAGE][parentBranch]
          )
            return true;
          if (
            this.permissions[SYMBOL.PERMISSION.BRANCH_FINANCE] &&
            this.permissions[SYMBOL.PERMISSION.BRANCH_FINANCE][parentBranch]
          )
            return true;
          if (
            this.permissions[SYMBOL.PERMISSION.BRANCH_REPORT] &&
            this.permissions[SYMBOL.PERMISSION.BRANCH_REPORT][parentBranch]
          )
            return true;
        }

      return false;
    },

    editUser() {
      this.$router.push({ name: ROUTE.USER_ACCOUNT_EDIT, params: { userId: this.user.id } });
    },

    async loginAsUser() {
      const loginResponse = await loginAsTemporaryUser(this.userId).execute();
      const { loginUser, user, saveOriginalCredentials, resetDefaults } = useUserStore();
      const adminToken = Cookies.get(AUTH_COOKIE_KEY);
      saveOriginalCredentials(user!, adminToken!);
      resetDefaults();
      await loginUser(loginResponse.data.user, loginResponse.data.token);
    },

    async setState(state: string) {
      this.dialogs
        .confirm(this.$t('userAccount.label.changing_user_state'), this.$t('userAccount.message.changing_user_state'))
        .then(() => {
          setUserState(this.user.id!.toString(), state)
            .execute()
            .then(() => {
              this.reload();
            });
        });
    },

    async addOrganization() {
      this.dialogs
        .confirm(
          this.$t('userAccount.label.changing_user_organization'),
          this.$t('userAccount.message.changing_user_organization'),
        )
        .then(() => {
          setUserOrganization(this.user.id!, this.changedOrganizationId)
            .withLoader(this.loader)
            .execute()
            .then(() => {
              this.changingOrganization = false;
              this.changedOrganizationId = '';
              this.reload();
            });
        });
    },

    async removeOrganization() {
      this.dialogs
        .confirm(
          this.$t('userAccount.label.changing_user_organization'),
          this.$t('userAccount.message.changing_user_organization'),
        )
        .then(() => {
          removeUserOrganization(this.user.id!, this.changedOrganizationId)
            .withLoader(this.loader)
            .execute()
            .then(() => {
              this.changingOrganization = false;
              this.changedOrganizationId = '';
              this.reload();
            });
        });
    },

    changeUserPassword() {
      this.$router.push({ name: ROUTE.USER_ACCOUNT_CHANGE_PASSWORD });
    },

    requestUserAccountDelete() {
      this.dialogs
        .confirm(this.$t('userAccount.label.deleting_user'), this.$t('userAccount.message.deleting_user_question'))
        .then(() => {
          this.dialogs.authorizeAction().then(() => {
            deleteUserAccount(this.user.id!)
              .withLoader(this.loader)
              .execute()
              .then(() => {
                this.toast({
                  title: this.$t('toasts.type.success'),
                  description: this.$t('userAccount.message.user_deleted'),
                });
              });
            this.$router.replace({ name: ROUTE.USER_ACCOUNT_LIST });
          })
        });
    },

    async togglePermission(symbol: string, branchId: string | null = null) {
      if (this.hasPermission(symbol, branchId)) {
        if (branchId)
          await revokePermission(this.userId, this.permissions[symbol][branchId]).withLoader(this.loader).execute();
        else await revokePermission(this.userId, this.permissions[symbol]['all']).withLoader(this.loader).execute();
      } else {
        await grantPermission(this.userId, symbol, this.currentOrganizationId, branchId)
          .withLoader(this.loader)
          .execute();
      }
      await this.reloadPermissions();
    },
  },
});
</script>
<style scoped>
.custom-grid {
  grid-template-columns: 50px 50px 150px 200px 250px 200px;
}

@media (min-width: 768px) {
  .custom-grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
</style>
