<template>
  <DataLoader ref="loader">
    <FilterBar :filters="filters" symbol="mcontract.filterbar" class="py-4" @update-filter="updateFilter" />
    <Paginator :data="contractDataSet" />
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead class="text-right">{{ $t('contracts.label.status') }}</ZTableHead>
            <ZTableHead class="whitespace-nowrap"
              >{{ $t('contracts.label.contract_number') }} / {{ $t('contracts.label.priority') }} /
              {{ $t('contracts.label.actions') }}
            </ZTableHead>
            <ZTableHead>{{ $t('contracts.label.assets_actions_count') }}</ZTableHead>
            <ZTableHead class="text-right">{{ $t('common.carbon_value') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.created_at') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.estimated_deadline_at_short') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.author') }}</ZTableHead>
            <ZTableHead>{{ $t('contracts.label.organization') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow
            v-for="(item, index) in contractDataSet.data"
            :key="index"
            class="cursor-pointer"
            @click="details(item.id ?? 0)"
          >
            <ZTableCell class="text-right">
              <PrettyStatus class="whitespace-nowrap" :status="item.status!" :translate="'contract.status.'" />
            </ZTableCell>
            <ZTableCell class="gap-1 items-center max-w-[20vw]">
              <div>
                <span>{{ item.contract_full_number }}</span
                >/
                <span class="text-xs border inline-block p-1">{{ priorities[item!.priority!] }}</span>
              </div>
              <div v-if="item.contracted_actions!.length" class="w-full mt-1">
                <span v-for="(action, index) in item.contracted_actions" :key="index" class="text-xs"
                  >{{ action!.action!.name }}{{ index == item.contracted_actions!.length - 1 ? '' : ', ' }}
                </span>
              </div>
            </ZTableCell>
            <ZTableCell class="text-center"
              ><span>{{ item.contracted_actions_count }}/{{ item.contracted_assets_count }}</span></ZTableCell
            >
            <ZTableCell class="text-right">
              <span class="font-bold">{{
                item?.carbon_footprint ? readableCarbonValue(item?.carbon_footprint) : '-'
              }}</span>
            </ZTableCell>

            <ZTableCell
              ><span>{{ formatDate(item.created_at!) }}</span></ZTableCell
            >
            <ZTableCell
              ><span v-if="item.estimated_deadline_at">{{ formatDate(item.estimated_deadline_at!) }}</span></ZTableCell
            >
            <ZTableCell
              ><span>{{ item.user_name }}</span></ZTableCell
            >
            <ZTableCell>
              <div class="flex flex-col">
                <b class="font-bold">{{ item.organization!.name }}</b>
              </div>
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="contractDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg" />
        {{ $t('contracts.message.data_not_found') }}
      </div>
    </div>
    <Paginator :data="contractDataSet" />
    <router-view></router-view>
  </DataLoader>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ZTable, ZTableBody, ZTableHead, ZTableHeader, ZTableRow, ZTableCell } from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import { DataSet, FilterParam } from '@/utils/DataSet';
import { ROUTE } from '@/router/routeNames';
import { KeyValue } from '@/types/Common';
import ContractData = App.Data.ContractData;
import ContractRequestData = App.Data.ContractRequestData;
import { getManagedContracts } from '@/api/contract';
import { PRIORITY } from '@/consts';
import symbolsStore from '@/stores/symbols';
import PrettyStatus from '@ui/PrettyStatus.vue';
import ActionData = App.Data.ActionData;
import FilterBar from '@ui/FilterBar.vue';
import { getOrganizationsCompact } from '@/api';
import DataLoader from '@ui/DataLoader.vue';
import OrganizationCompactData = App.Data.OrganizationCompactData;
import rememberStore from "@/stores/remember";

export default defineComponent({
  components: {
    DataLoader,
    FilterBar,
    PrettyStatus,
    ZTableHead,
    ZTableBody,
    ZTable,
    Paginator,
    ZTableHeader,
    ZTableRow,
    ExclamationTriangleIcon,
    ZTableCell,
  },

  data() {
    return {
      contractDataSet: {} as DataSet<ContractData, Array<FilterParam>>,
      statuses: {} as KeyValue,
      priorities: {} as KeyValue,
      loader: null as any,

      filters: [
        { field: 'search', label: 'Wyszukaj', type: 'search' },
        {
          field: 'sort',
          label: this.$t('filtering.labels.sorting'),
          type: 'dropdown',
          items: {},
        },
        {
          field: 'range',
          label: this.$t('filtering.labels.date_range'),
          type: 'date_range',
        },
        {
          field: 'organization',
          label: this.$t('filtering.labels.organization'),
          type: 'dropdown',
          items: {},
        },
        {
          field: 'action',
          label: this.$t('filtering.labels.action'),
          type: 'dropdown',
          items: {},
        },
        {
          field: 'status',
          label: this.$t('filtering.labels.status'),
          type: 'multi',
          items: {},
        },
      ],
    };
  },

  async mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;

    for (const [key, value] of Object.entries(PRIORITY)) {
      this.priorities[key] = this.$t('contracts.priority.' + value);
    }
    await this.getStates();
    await this.getActions();
    await this.getSorting();
    await this.getOrganizations();
  },

  methods: {
    details(id: number) {
      this.$router.push({ name: ROUTE.MANAGED_CONTRACT_DETAILS, params: { contractId: id } });
    },

    async getOrganizations() {
      const organizationsResponse = await getOrganizationsCompact().withLoader(this.loader).execute();
      let organizations = {} as KeyValue;
      organizations = organizationsResponse.data.reduce((accumulator: KeyValue, value: OrganizationCompactData) => {
        return { ...accumulator, [value.id]: value.name };
      }, {});

      this.filters[3].items = organizations;
    },

    async getStates() {
      const symbols = symbolsStore();
      let contract_states = {} as KeyValue;
      const states = await symbols.get('contract_states');
      for (const [key, value] of Object.entries(states)) {
        if (key == 'draft') continue;
        contract_states[key] = this.$t(value);
      }

      this.filters[5].items = contract_states;
      this.filters[5].default = Object.keys(contract_states);
    },

    async getSorting() {
      const symbols = symbolsStore();
      let contract_sorting = {} as KeyValue;
      const sorting = await symbols.get('contract_sorting');
      for (const [key, value] of Object.entries(sorting)) {
        contract_sorting[key] = this.$t(value);
      }

      this.filters[1].items = contract_sorting;
      this.filters[1].default = 'newest';
    },

    async getActions() {
      const symbols = symbolsStore();
      let contract_actions = {} as KeyValue;
      const actions = await symbols.get('contract_actions');
      contract_actions = actions.reduce((accumulator: KeyValue, value: ActionData) => {
        return { ...accumulator, [value.id!]: value.name };
      }, {});

      this.filters[4].items = contract_actions;
    },

    async updateFilter(filterString: string, filterValuesArray: any) {
      this.contractDataSet = new DataSet<ContractData, ContractRequestData>((query: string) =>
        getManagedContracts().withLoader(this.loader).execute(query),
        () => {
          rememberStore().memorize('mcontract.filters', {page: this.contractDataSet.meta?.current_page, page_size: this.contractDataSet.meta?.per_page})
        }).setParams({ filter: filterString }, true);
      await this.contractDataSet.resetToFirstPage();
    },
  },
});
</script>
