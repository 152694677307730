import { AxiosResponse } from 'axios';

export interface FilterParam {
  field: string;
  values: Array<string>;
}

export interface PaginationMetadata {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
  from: number;
  to: number;
}

export interface PaginatedResponse<ItemType> {
  meta: PaginationMetadata;
  data: ItemType[];
}

export class DataSet<ItemType, ParamsType> {
  public data?: ItemType[];
  public meta?: PaginationMetadata;
  public params?: ParamsType;
  public readonly reader: (query: string) => Promise<AxiosResponse<PaginatedResponse<ItemType>>>;
  public readonly afterLoad: () => void;
  public debounceTimer?: ReturnType<typeof setTimeout>;
  public perPage?: number | null;

  constructor(reader: (query: string) => Promise<AxiosResponse<PaginatedResponse<ItemType>>>, afterLoad: () => void = null) {
    this.reader = reader;
    this.afterLoad = afterLoad;
  }

  setParams(newParams: ParamsType, clear: boolean = false) {
    if (clear) {
      this.params = newParams;
      return this;
    }

    for (const newParamKey in newParams) {
      if (newParams[newParamKey] == undefined) {
        if (this.params && this.params[newParamKey] != undefined) delete this.params[newParamKey];
        delete newParams[newParamKey];
      }
    }
    this.params = {
      ...this.params,
      ...newParams,
    };

    return this;
  }

  loadDebounced(page: number | null = null) {
    if (this.debounceTimer != undefined) {
      clearTimeout(this.debounceTimer);
    }

    return new Promise<AxiosResponse<PaginatedResponse<ItemType>>>((resolve, reject) => {
      this.debounceTimer = setTimeout(() => {
        this.load(page).then(resolve, reject);
        this.debounceTimer = undefined;
      }, 150);
    });
  }

  public async load(page: number | null = null) {
    if (!page) {
      if (this.meta) {
        page = this.meta.current_page;
      } else {
        page = 1;
      }
    }

    const params = {
      ...this.params,
      page: page,
    } as any;

    const query = '?' + new URLSearchParams(this.buildQueryParams(null, params));

    const response = await this.reader(query);
    this.data = response.data.data;
    this.meta = response.data.meta;

    if (this.afterLoad != null) this.afterLoad();

    return response;
  }

  public nextPage() {
    if (!this.meta) {
      return;
    }

    return this.load(this.meta.current_page + 1);
  }

  public previousPage() {
    if (!this.meta) {
      return;
    }

    return this.load(this.meta.current_page - 1);
  }

  public setPageSize(size: number) {
    this.perPage = size;
    this.params['page_size'] = this.perPage;
  }

  public jumpTo(pageNumber: number) {
    return this.load(pageNumber);
  }

  public resetToFirstPage() {
    if (this.meta) this.meta.current_page = 1;
    return this.loadDebounced(1);
  }

  public getQueryPathWithKey(path: string | null, key: string) {
    return typeof path === 'string' ? `${path}[${key}]` : key;
  }

  public buildQueryParams = (path: string | null, params: object): any[] | string => {
    return Object.entries(params).flatMap(([key, value]) => {
      const pathWithKey = this.getQueryPathWithKey(path, key);
      if (toString.call(value) === '[object Array]' || toString.call(value) === '[object Object]') {
        return this.buildQueryParams(pathWithKey, value);
      }
      return [[pathWithKey, value]];
    });
  };
}
